<template>
    <v-chart :options="chartOptions" style="height: 100%; width: 100%;"></v-chart>
</template>

<script>

export default {
    props: ["dailyData"],
    data() {
        return {
            chartOptions: {}
        };
    },
    mounted() {
        this.chartOptions = this.getChartOptions();
    },
    methods: {
        // 차트 옵션을 반환하는 함수
        getChartOptions() {
            return {
                tooltip: {
                    position: 'top',
                    formatter: function (params) {
                        return `
                                <div style="font-size: 14px;">
                                    ${params.marker} 
                                    <span>${params.value[0]}</span>: 
                                    <span style="font-weight: bold;">${params.value[1]} kWh</span>
                                </div>
                                `
                    },
                },
                visualMap: [
                    {
                        show: false,
                        min: 0,
                        max: 1000,
                        calculable: true,
                        orient: 'horizontal',
                        left: '55%',
                        bottom: 20
                    },
                ],
                calendar: [
                    {
                        orient: 'vertical',
                        yearLabel: {
                            margin: 50
                        },
                        dayLabel: {
                            firstDay: 0,
                            nameMap: ['일', '월', '화', '수', '목', '금', '토']
                        },
                        monthLabel: {
                            nameMap: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
                            margin: 20
                        },
                        cellSize: ['auto', 40],
                        top: 150,
                        left: 60,
                        right: 60,
                        range: this.dailyData[0][0].slice(0, 7),
                    }
                ],
                series: [
                    {
                        type: 'heatmap',
                        coordinateSystem: 'calendar',
                        // data: this.getVirtualData(String(new Date().getFullYear()))
                        data: this.dailyData,
                    }
                ]
            };
        }
    }
};
</script>