<template>
    <v-chart class="gauge-chart" :options="data" ref="charts" />
</template>

<style scoped>
.gauge-chart {
    height: 100%;
    width: 100%;
}
</style>

<script>

export default {
    props: ["data", "col", "height"],
    data() {
        return {}
    },
    mounted() {
        // Resize 이벤트 핸들러 등록
        console.log('data :', this.data);
        
        this.$nextTick(() => {
            window.addEventListener('resize', this.resizeCharts);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeCharts);
    },
    methods: {
        resizeCharts() {
            this.$refs.charts.forEach(chart => chart.resize());
        }
    }
}

</script>