<template>
    <div style="width: 100%;">
        <Timeline class="timelineCtn" ref="timelineCtn" :value="events" layout="horizontal" align="alternate">
            <template #content="slotProps">
                <Card>
                    <template #content>
                        <div class="d-flex flex-column" style="width: 100%; cursor: pointer;"
                            @click="hadleDetailModal(slotProps.item)">
                            <div class="d-flex flex-row align-items-center" :style="{
                                backgroundColor: '#f2f2f2', color: slotProps.item.plan ? '#ff5b57' : '#444', fontSize: '14px', flex: 1,
                                width: '100%', 'justify-content': 'space-between', marginBottom: '4px', padding: '2px 8px 2px 8px', borderRadius: '40px'
                            }">
                                <div style="text-align: left;">
                                    <span>
                                        <i class="fas" :class="slotProps.item.eventIcon" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <div style="text-align: center;">
                                    <span style="font-weight: 600;">
                                        {{ slotProps.item.eventHour ? slotProps.item.eventHour : "-" }}
                                    </span>
                                </div>
                                <div style="text-align: right;">
                                    <span v-if="slotProps.item.hist">
                                        <i class="fas fa-check" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex flex-row align-items-center" style="flex: 1; width: 100%;">
                                <div style="flex: 1; text-align: center;">
                                    <span style="color: #777; font-size: 0.75rem; white-space: nowrap;">
                                        {{ slotProps.item.ctrlName ? slotProps.item.ctrlName : "-" }}
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex flex-row align-items-center" style="flex: 1; width: 100%;">
                                <div style="flex: 1; text-align: center;">
                                    <span style="font-weight: 600; font-size: 0.75rem; white-space: nowrap;">
                                        {{ slotProps.item.eventName ? slotProps.item.eventName : "-" }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </template>
                </Card>
            </template>
        </Timeline>
        <CtrlLogModal v-if="ctrlLog" :ctrlLog="ctrlLog" ref="CtrlLogModal" @clear-data="clearCtrlLog" />
        <ScheduleDetailModal v-if="scheduleDetail" :detail="scheduleDetail" ref="ScheduleDetailModal"
            @clear-data="clearSchedultDetail" />
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import CtrlLogModal from '../modal/CtrlLogModal.vue';
import ScheduleDetailModal from '../modal/ScheduleDetailModal.vue';

export default {
    props: ["events"],
    components: {
        CtrlLogModal,
        ScheduleDetailModal,
    },
    data() {
        return {
            ctrlLog: null,
            scheduleDetail: null,
        }
    },
    computed: {},
    created() { },
    mounted() {
        this.$nextTick(() => {
            const timelineScroll = document.querySelector(".timelineCtn");
            if (!timelineScroll) return;
            else this.addScrollEventListeners(timelineScroll)
        })
    },
    beforDestroyed() {
        const timelineScroll = document.querySelector(".timelineCtn");
        if (timelineScroll) this.removeScrollEventListeners(timelineScroll);
    },
    methods: {
        hadleDetailModal(data) {
            if (data.hist) this.openControlLog(data);
            else if (data.plan) this.openScheduleDetail(data);
            else return;
        },
        async openControlLog(data) {
            if (data) {
                try {
                    let result = null

                    if (data.hist.ctrlTarget === 'Group')
                        result = await backEndApi.sysNode.getGroupCtrlLog(data.hist.outputIdx);
                    else
                        result = await backEndApi.sysNode.getEquipCtrlLog(data.hist.outputIdx);

                    if (result.status == 200) {
                        this.ctrlLog = result.data;
                        this.$nextTick(() => {
                            this.$refs.CtrlLogModal.showControlLogModal()
                        })
                    }
                } catch (e) {
                    console.error(e)
                }
            }
        },
        async openScheduleDetail(data) {
            try {
                let result = await backEndApi.equipControl.getEquipCtrlSchDetail(data.plan.schdCtrlIdx);
                if (result.status == 200) {
                    this.scheduleDetail = result.data;
                    this.$nextTick(() => {
                        this.$refs.ScheduleDetailModal.showScheduleModal();
                    })
                }
            } catch (e) {
                console.error(e);
            }
        },
        clearCtrlLog() {
            this.ctrlLog = null;
        },
        clearSchedultDetail() {
            this.scheduleDetail = null;
        },
        addScrollEventListeners(scrollContainer) {
            this.scrollContainer = scrollContainer;
            this.isDown = false;
            this.startX = 0;
            this.scrollLeft = 0;

            this.handleMouseDown = (e) => {
                this.isDown = true;
                scrollContainer.classList.add('active');
                this.startX = e.pageX - scrollContainer.offsetLeft;
                this.scrollLeft = scrollContainer.scrollLeft;
            };

            this.handleMouseLeave = () => {
                this.isDown = false;
                scrollContainer.classList.remove('active');
            };

            this.handleMouseUp = () => {
                this.isDown = false;
                scrollContainer.classList.remove('active');
            };

            this.handleMouseMove = (e) => {
                if (!this.isDown) return;
                e.stopPropagation();
                const x = e.pageX - scrollContainer.offsetLeft;
                const walk = (x - this.startX) * 2;
                scrollContainer.scrollLeft = this.scrollLeft - walk;
            };

            scrollContainer.addEventListener('mousedown', this.handleMouseDown);
            scrollContainer.addEventListener('mouseleave', this.handleMouseLeave);
            scrollContainer.addEventListener('mouseup', this.handleMouseUp);
            scrollContainer.addEventListener('mousemove', this.handleMouseMove);
        },
        removeScrollEventListeners(scrollContainer) {
            scrollContainer.removeEventListener('mousedown', this.handleMouseDown);
            scrollContainer.removeEventListener('mouseleave', this.handleMouseLeave);
            scrollContainer.removeEventListener('mouseup', this.handleMouseUp);
            scrollContainer.removeEventListener('mousemove', this.handleMouseMove);
        },
    },
}

</script>