<template>
    <div v-if="baseData" style="height: 100%;">
        <div ref="lineChart" style="width: 100%; height: 100%;"></div>
    </div>
</template>

<script>
import moment from 'moment';
import * as echarts from 'echarts';

// const primaryColor = '#E57373';
// const secondaryColor = '#64B5F6';
const primaryColor = '#FF5722';
const secondaryColor = '#B0BEC5';

function formatNumber(num) {
    let val = num.toLocaleString('en-US', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
    });
    return val;
}

export default {
    data() {
        return {
            xAxisLabel: null,
            baseDataName: "",
            baseDataset: [],
            compDataName: "",
            compDataset: [],
            unitMap: {},
        }
    },
    // props: ["compData", "baseData", "xAxisLabel", "dispBaseIdx", "dispCompIdx"],
    props: ["baseData", "compData", "dispBaseIdx", "dispCompIdx"],
    created() { },
    mounted() {
        if (!this.isEmpty(this.compData)) {
            console.log('LineChartPanel.mounted() : ', this.dispCompIdx);

            // this.dispCompIdx = this.compData.headers[0].ptIdx;
            this.prepareChartData();
        }
    },

    computed: {},
    watch: {
        dispCompIdx() {
            console.log('LineChartPanel is updated. ', this.dispCompIdx)
            this.prepareChartData();
        }
    },
    methods: {
        prepareChartData() {
            this.compDataset = [];

            if (!this.isEmpty(this.compData)) {

                // 주 트랜드 데이터셋 처리 (baseData)
                this.baseDataName = this.baseData.headers.text;
                this.baseDataset = this.baseData.results.map(item => item[this.dispBaseIdx] ?? 0)
                // this.baseDataset = this.baseData.results.map(item => [item[this.dispBaseIdx] ?? 0, nodeUnit])
                this.xAxisLabel = this.baseDataset.map(item => moment(item.regDt).format('MM-DD'))
                let nodeUnit = this.$store.state.units.find(unit => unit.value == this.baseData.headers.sumUnit).text ?? 'kWh';
                this.unitMap[this.baseDataName] = nodeUnit;

                // 비교 트랜드 데이터셋 처리 (compData)
                let found = this.compData.headers.find(item => item.ptIdx == this.dispCompIdx);
                this.compDataName = found.ptName;
                this.compDataset = this.compData.results.map(item => item[this.dispCompIdx] ?? 0)
                // this.compDataset = this.compData.results.map(item => [item[this.dispCompIdx] ?? 0, rldUnit])
                let rldUnit = this.$store.state.units.find(unit => unit.value == found.unitSmallCode).text;
                this.unitMap[this.compDataName] = rldUnit;

                // Chart rendering
                this.renderChart();
            }
        },

        renderChart() {
            const chart = echarts.init(this.$refs.lineChart);

            // Closure Variables for Chart Tooltip Formatting
            let unitMap = this.unitMap;

            const option = {
                // title: {
                //     text: `${this.baseDataName} vs ${this.compDataName}`,
                //     left: 'center'
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        animation: true,
                    },
                    formatter: function (params) {
                        return ` <div style="font-size: 14px; width: 230px;">
                                    <div class="mb-3">
                                        <p class="m-0 p-0">${params[0].axisValue}</p>
                                        <div style="display: flex; justify-content: space-between;">
                                            <div>
                                                ${params[0].marker}
                                                <span>${params[0].seriesName}</span>
                                            </div>
                                            <span style="font-weight: bold;">${formatNumber(params[0].data) + ' ' + unitMap[params[0].seriesName] }</span>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="m-0 p-0">${params[1].axisValue}</p>
                                        <div style="display: flex; justify-content: space-between;">
                                            <div>
                                                ${params[1].marker}
                                                <span>${params[1].seriesName}</span>
                                            </div>
                                            <span style="font-weight: bold;">${formatNumber(params[1].data) + ' ' + unitMap[params[1].seriesName] }</span>
                                        </div>
                                    </div>
                                </div> `;
                    }
                },
                legend: {
                    data: [this.baseDataName, this.compDataName],
                    left: 10,
                    show: false,
                },

                axisPointer: {
                    link: [
                        {
                            xAxisIndex: 'all'
                        }
                    ]
                },
                // dataZoom: [
                //     {
                //         show: false,
                //         realtime: true,
                //         start: 30,
                //         end: 70,
                //         xAxisIndex: [0, 1]
                //     },
                //     {
                //         type: 'inside',
                //         realtime: true,
                //         start: 30,
                //         end: 70,
                //         xAxisIndex: [0, 1]
                //     }
                // ],
                grid: [
                    {
                        left: 70,
                        right: 70,
                        height: '45%'
                    },
                    {
                        left: 70,
                        right: 70,
                        top: '64%',
                        height: '25%'
                    }
                ],
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        axisLine: { onZero: true },
                        data: this.xAxisLabel,
                        axisLabel: {
                            align: 'left',
                        },
                    },
                    {
                        show: false,
                        gridIndex: 1,
                        type: 'category',
                        boundaryGap: false,
                        axisLine: { onZero: true },
                        data: this.xAxisLabel,
                        position: 'top',
                        axisLabel: {
                            align: 'left',
                        },
                    }
                ],
                yAxis: [
                    {
                        name: `${this.baseDataName} (${this.unitMap[this.baseDataName]})`,
                        type: 'value',
                        axisLabel: {
                            align: 'right',
                            padding: [0, 10, 0, 0]
                        },
                        formatter: function (value, index) {
                            // index가 0일 때 빈 문자열을 반환하여 첫 번째 라벨 숨기기
                            return index === 0 ? '' : value;
                        }
                        // max: 3000
                    },
                    {
                        name: `${this.compDataName} (${this.unitMap[this.compDataName]})`,
                        gridIndex: 1,
                        type: 'value',
                        inverse: true,
                        axisLabel: {
                            align: 'right',
                            padding: [0, 10, 0, 0]
                        },
                        formatter: function (value, index) {
                            // index가 0일 때 빈 문자열을 반환하여 첫 번째 라벨 숨기기
                            return index === 0 ? '' : value;
                        }
                    }
                ],
                series: [
                    {
                        name: `${this.baseDataName}`,
                        type: 'line',
                        smooth: 0.4,
                        symbolSize: 8,
                        data: this.baseDataset,
                        lineStyle: {
                            color: primaryColor,
                            width: 2
                        },
                        itemStyle: {
                            color: primaryColor
                        },
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: primaryColor },
                                { offset: 1, color: primaryColor + '00' }
                            ])
                        }
                    },
                    {
                        name: `${this.compDataName}`,
                        type: 'line',
                        smooth: 0.4,
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        symbolSize: 8,
                        data: this.compDataset,
                        lineStyle: {
                            color: secondaryColor,
                            width: 2
                        },
                        itemStyle: {
                            color: secondaryColor
                        },
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: secondaryColor + '00' },
                                { offset: 1, color: secondaryColor },
                            ])
                        }
                    }
                ]
            };

            chart.setOption(option);
            window.addEventListener("resize", () => {
                chart.resize();
            });
        }
    }
}
</script>

<style scoped>
.externalStateMenu {
    display: flex;
    justify-content: end;
}
</style>
