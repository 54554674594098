import { generateColorGradient } from "../lib/colorGradient";
import store from '@src/config/PageStore.vue'

export default {
    reorderObject(obj) {
        const { summary, ...rest } = obj;
        return { summary, ...rest };
    },
    formatChartDatas(baseData, currentNode, rows, queryType) {
        console.log("queryType!!!!!!!!!!!!!!!!!!!!!!!!!!!", queryType)
        let customData;
        if(!baseData.summary) delete baseData.summary;
        console.log("customData!!!!", customData)
        return Object.keys(customData).map((key, index) => {
            const headers = customData[key].headers;
            const results = customData[key].results;
            const startColor = '#E3F2FD';
            const endColor = '#0D47A1';
            const backgroundColors = generateColorGradient(startColor, endColor, headers.length - 1);

            return {
                key: `Chart${index}`,
                flag: true,
                data: {
                    labels: results.map(result => {
                        const regDt = result.regDt.replace(/[-:TZ]/g, '');
                        const hour = regDt.substring(8, 10);
                        const returnDate = queryType !== 'MONTH' ? `${hour}:00` : result.regDt
                        return returnDate;
                    }),
                    datasets: headers.map((header, headerIndex) => {
                        const unit = store.state.units.find(unit => unit.value == header.unitSmallCode)?.text;
                        const borderColor = '#90909077';
                        const backgroundColor = backgroundColors[headerIndex];

                        return {
                            key: header.dispText ? header.dispText : header.propName,
                            propCode: header.propCode ? header.propCode : '',
                            unit: unit,
                            type: 'line',
                            lineTension: 0.5,
                            borderWidth: 1.3,
                            fill: true,
                            // label: unit ? header.dispText + `(${unit})` : header.dispText,
                            label: header.dispText ? header.dispText + `(${unit ? unit : '-'})` : header.propName,
                            pointRadius: 2,
                            showLine: true,
                            interpolate: true,
                            borderColor,
                            backgroundColor: backgroundColor + '44',
                            data: results.map(result => result[header.ptIdx] === undefined ? result[header.propCode] : result[header.ptIdx])
                        };
                    })
                },
                option: {
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: {
                        duration: 1000,
                        easing: "easeOutQuart",
                    },
                    legend: {
                        display: true,
                        align: "end",
                        labels: {
                            boxWidth: 12,
                            fontColor: "#000000",
                            fontSize: 12
                        }
                    },
                    title: {
                        display: true,
                        align: "start",
                        position: "top",
                        text: rows.find(row => row.equipIdx == key) ? 
                                rows.find(row => row.equipIdx == key).equipName : 
                                '집계값',
                        fontSize: 12,
                        fontColor: "#000000",
                    },
                    layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
                    hover: {
                        intersect: false,
                    },
                    elements: {
                        point: {
                            hitRadius: 20,          // 포인트 클릭 반경 조절
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                id: "x-axis-0",
                                stacked: false,
                                offset: true,
                                ticks: {
                                    maxTicksLimit: 10,
                                    autoSkip: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                    maxRotation: 0,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                id: "Left_yAxis",
                                display: true,
                                position: "left",
                                stacked: false,
                                ticks: {
                                    maxTicksLimit: 5,
                                    beginAtZero: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                },
                            },
                        ],
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                        position: 'nearest',
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                let value = Math.round(tooltipItem.yLabel * 100) / 100;
                                return `${dataset.key} : ${value} ${dataset.unit ?? ''}`
                            },
                        },
                    }
                }
            }

        })
    },
    formatSystemDatas(baseData, currentNode, rows, queryType) {
        let customData = {};
        Object.keys(baseData).forEach(key => {
            Object.keys(baseData[key]).forEach(key2 => {
                if(!baseData[key][key2]) {
                    console.log("key2", key2)
                    delete baseData[key][key2];
                }
                else customData[key2] = baseData[key][key2]
            })
        })
        console.log("customData!!!!!", customData)
        return Object.keys(customData).map((key, index) => {
            const headers = customData[key].headers;
            const results = customData[key].results;
            const startColor = '#E3F2FD';
            const endColor = '#0D47A1';
            const backgroundColors = generateColorGradient(startColor, endColor, headers.length - 1);

            return {
                key: `Chart${index}`,
                flag: true,
                data: {
                    labels: results.map(result => {
                        const regDt = result.regDt.replace(/[-:TZ]/g, '');
                        const hour = regDt.substring(8, 10);
                        const returnDate = queryType !== 'MONTH' ? `${hour}:00` : result.regDt
                        return returnDate;
                    }),
                    datasets: headers.map((header, headerIndex) => {
                        const unit = store.state.units.find(unit => unit.value == header.unitSmallCode)?.text;
                        const borderColor = '#90909077';
                        const backgroundColor = backgroundColors[headerIndex];

                        return {
                            key: header.dispText ? header.dispText : header.propName,
                            propCode: header.propCode ? header.propCode : '',
                            unit: unit,
                            type: 'line',
                            lineTension: 0.5,
                            borderWidth: 1.3,
                            fill: true,
                            label: header.dispText ? header.dispText + `(${unit ? unit : '-'})` : header.propName,
                            pointRadius: 2,
                            showLine: true,
                            interpolate: true,
                            borderColor,
                            backgroundColor: backgroundColor + '44',
                            data: results.map(result => result[header.ptIdx] === undefined ? result[header.propCode] : result[header.ptIdx])
                        };
                    })
                },
                option: {
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: {
                        duration: 1000,
                        easing: "easeOutQuart",
                    },
                    legend: {
                        display: true,
                        align: "end",
                        labels: {
                            boxWidth: 12,
                            fontColor: "#000000",
                            fontSize: 12
                        }
                    },
                    title: {
                        display: true,
                        align: "start",
                        position: "top",
                        text: rows.find(row => row.equipIdx == key) ? 
                                rows.find(row => row.equipIdx == key).equipName : 
                                '집계값',
                        fontSize: 12,
                        fontColor: "#000000",
                    },
                    layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
                    hover: {
                        intersect: false,
                    },
                    elements: {
                        point: {
                            hitRadius: 20,          // 포인트 클릭 반경 조절
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                id: "x-axis-0",
                                stacked: false,
                                offset: true,
                                ticks: {
                                    maxTicksLimit: 10,
                                    autoSkip: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                    maxRotation: 0,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                id: "Left_yAxis",
                                display: true,
                                position: "left",
                                stacked: false,
                                ticks: {
                                    maxTicksLimit: 5,
                                    beginAtZero: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                },
                            },
                        ],
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                        position: 'nearest',
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                let value = Math.round(tooltipItem.yLabel * 100) / 100;
                                return `${dataset.key} : ${value} ${dataset.unit ?? ''}`
                            },
                        },
                    }
                }
            }

        })
    },
    formatChartDatasByProp(baseData, pointInfo, queryType) {
        delete baseData.summary;
        const gradientLevel = Object.entries(baseData).length
        // const startColor = '#ECEFF1';
        // const endColor = '#263238';
        const startColor = '#FBE9E7';
        const endColor = '#BF360C';

        const ptInfo = pointInfo

        const backgroundColors = generateColorGradient(startColor, endColor, gradientLevel - 1);

        const getPropCodes = (baseData) => {
            const propCodesMap = new Map();
            Object.values(baseData).forEach(data => {
                // console.log('headers:', data.headers);
                data.headers.forEach(header => {
                    const { propCode, dispText, unitSmallCode } = header;
                    const unit = store.state.units.find(unit => unit.value == unitSmallCode)?.text;
                    if (!propCodesMap.has(propCode)) {
                        propCodesMap.set(propCode, { dispText, unit });
                    }
                });
            });
            return Array.from(propCodesMap, ([propCode, { dispText, unit }]) => ({ propCode, dispText, unit }));
        };

        // console.log("baseData :", baseData);
        const propCodes = getPropCodes(baseData);

        const borderColor = '#90909044';

        return propCodes.map((prop) => {
            const datasets = [];

            Object.keys(baseData).forEach((key, index) => {
                const headers = baseData[key].headers.filter(header => header.propCode === prop.propCode);
                const results = baseData[key].results;

                headers.forEach((header) => {
                    // console.log('### header:', header)
                    const point = ptInfo.find(item => item.ptIdx == header.ptIdx);

                    datasets.push({
                        key: `${key}_${header.dispText}`,
                        propCode: header.propCode,
                        propName: header.dispName,
                        unit: header.unit,
                        type: 'line',
                        lineTension: 0.5,
                        borderWidth: 1.3,
                        fill: true,
                        label: `${point ? point.equipName : ''}`,
                        pointRadius: 2,
                        showLine: true,
                        interpolate: true,
                        borderColor: borderColor,
                        backgroundColor: backgroundColors[index] + '44',
                        data: results.map(result => result[header.ptIdx] === undefined ? null : result[header.ptIdx])
                    });
                });
            });

            return {
                key: `Chart_${prop.propCode}`,
                flag: true,
                data: {
                    labels: Object.values(baseData)[0].results.map(result => {
                        const regDt = result.regDt.replace(/[-:TZ]/g, '');
                        const hour = regDt.substring(8, 10);
                        const returnDate = queryType !== 'MONTH' ? `${hour}:00` : result.regDt
                        return returnDate;
                    }),
                    datasets: datasets
                },
                option: {
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: {
                        duration: 1000,
                        easing: "easeOutQuart",
                    },
                    legend: {
                        display: true,
                        align: "end",
                        labels: {
                            boxWidth: 12,
                            fontColor: "#000000",
                            fontSize: 12
                        }
                    },
                    title: {
                        display: true,
                        align: "start",
                        position: "top",
                        // text: `${prop.dispText ?? prop.propCode} (${prop.unit ?? '-'})`,
                        text: prop.unit ? `${prop.dispText ?? prop.propCode} (${prop.unit})` : `${prop.dispText ?? prop.propCode}`,
                        fontSize: 12,
                        fontColor: "#000000",
                    },
                    layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
                    hover: {
                        mode: 'index',
                        intersect: true,
                        animationDuration: 400,

                    },
                    // elements: {
                    //     point: {
                    //         hitRadius: 20,
                    //     },
                    // },
                    scales: {
                        xAxes: [
                            {
                                id: "x-axis-0",
                                stacked: false,
                                offset: true,
                                ticks: {
                                    maxTicksLimit: 10,
                                    autoSkip: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                    maxRotation: 0,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                id: "Left_yAxis",
                                display: true,
                                position: "left",
                                stacked: false,
                                ticks: {
                                    maxTicksLimit: 5,
                                    beginAtZero: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                },
                            },
                        ],
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                        // position: 'nearest',
                        callbacks: {
                            label: function (tooltipItem, data) {
                                let dataset = data.datasets[tooltipItem.datasetIndex];
                                let value = Math.round(tooltipItem.yLabel * 100) / 100;
                                return `${dataset.label}: ${value} ${prop.unit ?? ''}`;
                            },
                        }
                    }
                }
            };
        });
    },

    equipFormatChartDatas(baseData, typeDetail, currentNode, rows, queryType) {
        console.log(typeDetail);
        delete baseData.summary;
        const charts = [];

        Object.keys(baseData).forEach((key, index) => {
            const headers = baseData[key].headers.filter(item => typeDetail.ioProps.some(type => type.propCode == item.propCode));
            const findCalc = baseData[key].headers.filter(item => typeDetail.calcProps.some(type => type.propCode == item.propCode));
            const findUser = baseData[key].headers.filter(item => typeDetail.userProps.some(type => type.propCode == item.propCode));
            if (findCalc.length > 0) headers.push( ...findCalc );
            if (findUser.length > 0) headers.push( ...findUser );
            const results = baseData[key].results;

            const startColor = '#ECEFF1';
            const endColor = '#263238';
            const backgroundColors = generateColorGradient(startColor, endColor, headers.length - 1)

            headers.forEach((header, headerIndex) => {

                const backgroundColor = backgroundColors[headerIndex];  // 단일 색상
                const unit = store.state.units.find(unit => unit.value == header.unitSmallCode)?.text;
                const borderColor = '#90909077';

                charts.push({
                    key: `Chart${index}_${header.ptIdx}`,
                    flag: true,
                    data: {
                        labels: results.map(result => {
                            const regDt = result.regDt.replace(/[-:TZ]/g, '');
                            const hour = regDt.substring(8, 10);
                            const returnDate = queryType !== 'MONTH' ? `${hour}:00` : result.regDt
                            return returnDate;
                        }),
                        datasets: [
                            {
                                key: header.dispText,
                                propCode: header.propCode,
                                unit: unit,
                                type: 'line',
                                lineTension: 0.5,
                                borderWidth: 1.3,
                                fill: true,
                                // label: header.dispText + `(${unit ?? '-'})`,
                                label: unit ? header.dispText + `(${unit})` : header.dispText,
                                pointRadius: 2,
                                showLine: true,
                                interpolate: true,
                                borderColor,
                                backgroundColor: backgroundColor + '44',
                                data: results.map(result => result[header.ptIdx] === undefined ? null : result[header.ptIdx])
                            }
                        ]
                    },
                    option: {
                        interaction: {
                            mode: 'index',
                            intersect: false,
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        animation: {
                            duration: 1000,
                            easing: "easeOutQuart",
                        },
                        // hoverMode: "index",
                        legend: {
                            display: true,
                            align: "end",
                            labels: {
                                boxWidth: 12,
                                fontColor: "#000000",
                                fontSize: 12
                            }
                        },
                        title: {
                            display: true,
                            align: "start",
                            position: "top",
                            text: currentNode.nodeType == "Group" ?
                                rows.find(row => row.equipIdx == key).equipName :
                                header.dispText,
                            fontSize: 12,
                            fontColor: "#000000",
                        },
                        layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
                        hover: {
                            // mode: 'index',
                            // intersect: true,
                            // animationDuration: 400,

                            intersect: false,
                        },
                        elements: {
                            point: {
                                hitRadius: 20,          // 포인트 클릭 반경 조절
                            },
                        },
                        scales: {
                            xAxes: [
                                {
                                    id: "x-axis-0",
                                    stacked: false,
                                    offset: true,
                                    ticks: {
                                        maxTicksLimit: 10,
                                        autoSkip: true,
                                        fontColor: "#000000",
                                        fontSize: 10,
                                        maxRotation: 0,
                                    },
                                },
                            ],
                            yAxes: [
                                {
                                    id: "Left_yAxis",
                                    display: true,
                                    position: "left",
                                    stacked: false,
                                    ticks: {
                                        maxTicksLimit: 5,
                                        beginAtZero: true,
                                        fontColor: "#000000",
                                        fontSize: 10,
                                    },
                                },
                            ],
                        },
                        tooltips: {
                            mode: 'index',
                            intersect: false,
                            position: 'nearest',
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    var dataset = data.datasets[tooltipItem.datasetIndex];
                                    // var label = dataset.label || '';
                                    // if (label) {
                                    //     label += ': ';
                                    // }
                                    // label += Math.round(tooltipItem.yLabel * 100) / 100;
                                    // return label;

                                    let value = Math.round(tooltipItem.yLabel * 100) / 100;
                                    return `${dataset.key} : ${value} ${dataset.unit ?? ''}`;

                                },
                            }
                        }
                    }
                });
            });
        });

        return charts;
    },
    formatRltdChartDatas(baseData, queryType) {
        return baseData.headers.map(item => {
            const { ptIdx, dispText, ptReltnType, unitSmallCode } = item;
            const results = baseData.results;
            const unit = store.state.units.find(unit => unit.value == unitSmallCode)?.text;
            let backgroundColor, borderColor;
            switch (ptReltnType) {
                case 'Factor': backgroundColor = '#CFD8DC66'; break;
                case 'Cstn': backgroundColor = '#FFCC8066'; break;
                case 'Prod': backgroundColor = '#B2DFDB66'; break;
                default: backgroundColor = '90909044'; break;
            }
            borderColor = '#90909077';

            return {
                key: `${ptIdx}-${dispText}`,
                flag: true,
                data: {
                    labels: results.map(result => {
                        const regDt = result.regDt.replace(/[-:TZ]/g, '');
                        const hour = regDt.substring(8, 10);
                        const returnDate = queryType !== 'MONTH' ? `${hour}:00` : result.regDt
                        return returnDate;
                    }),
                    datasets: [
                        {
                            key: dispText,
                            type: 'line',
                            lineTension: 0.5,
                            borderWidth: 1.3,
                            fill: true,
                            // label: dispText + `(${unit ?? '-'})`,
                            label: unit ? dispText + `(${unit})` : dispText,
                            pointRadius: 2,
                            showLine: true,
                            interpolate: true,
                            borderColor,
                            backgroundColor,
                            data: results.map(result => result[ptIdx] === undefined ? null : result[ptIdx])
                        }
                    ]
                },
                option: {
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: {
                        duration: 1000,
                        easing: "easeOutQuart",
                    },
                    // hoverMode: "index",
                    legend: {
                        display: true,
                        align: "end",
                        labels: {
                            // usePointStyle: true,
                            boxWidth: 12,
                            fontColor: "#000000",
                            fontSize: 12
                        }
                    },
                    title: {
                        display: true,
                        align: "start",
                        // text: dispText,
                        text: unit ? dispText + `(${unit})` : dispText,
                        fontSize: 12,
                        fontColor: "#000000",
                    },
                    layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
                    hover: {
                        // mode: 'index',
                        // intersect: true,
                        // animationDuration: 400,

                        intersect: false,
                    },
                    elements: {
                        point: {
                            hitRadius: 20,          // 포인트 클릭 반경 조절
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                id: "x-axis-0",
                                stacked: false,
                                offset: true,
                                ticks: {
                                    maxTicksLimit: 10,
                                    autoSkip: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                    maxRotation: 0,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                id: "Left_yAxis",
                                display: true,
                                position: "left",
                                stacked: false,
                                ticks: {
                                    maxTicksLimit: 5,
                                    beginAtZero: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                },
                            },
                        ],
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                        position: 'nearest',
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                var label = dataset.label || '';
                                if (label) {
                                    label += ': ';
                                }
                                label += Math.round(tooltipItem.yLabel * 100) / 100;
                                return label;
                            },
                        }
                    }
                }
            };
        });
    },
    maxValue(results) {
        let max = 0;
        results.forEach(result => {
            Object.keys(result).forEach(key => {
                // regDt 키는 숫자가 아니므로 제외
                if (key !== "regDt") {
                    const value = result[key];
                    if (value > max) {
                        max = value;
                    }
                }
            });
        });
        return max;
    },
    upperNumber(num) {
        const round = Math.round(num);
        const result = Math.round(round/10)*10;
        return result;
    },
    sumNodeFormatChartDatas(baseData, queryType) {
        console.log("queryType!!!!!!!!!!!!!!!!!!!!!!!!!!!", queryType)
        if(!baseData) return [];
        const headers = [{
            sysNodeIdx: baseData.headers.sysNodeIdx,
            text: baseData.headers.text,
            nodeType: baseData.headers.nodeType,
            sysType: baseData.headers.sysType,
            equipType: baseData.headers.equipType,
            sumUnit: baseData.headers.sumUnit,
            sumCondType: baseData.headers.sumCondType,
            sumCond: baseData.headers.sumCond,
            equipIdx: baseData.headers.equipIdx
        }];
        headers.push(...baseData.headers.children);
        const results = baseData.results;
        const startColor = '#E3F2FD';
        const endColor = '#0D47A1';
        const backgroundColors = generateColorGradient(startColor, endColor, headers.length - 1);
        const borderColor = '#90909077';

        let max = this.maxValue(results);
        let round;
        let upperNum;

        if(max > 1) {
            round = Math.round(max);
            upperNum = Math.ceil(round / 10) * 10;
            console.log(upperNum);
        }

        return headers.map((header, headerIndex) => {
            const unit = store.state.units.find(unit => unit.value == header.sumUnit)?.text
            return {
                key: `${header.sysNodeIdx}-${header.text}`,
                flag: true,
                data: {
                    labels: results.map(result => {
                        const regDt = result.regDt.replace(/[-:TZ]/g, '');
                        const hour = regDt.substring(8, 10);
                        const returnDate = queryType !== 'MONTH' ? `${hour}:00` : result.regDt
                        return returnDate;
                    }),
                    datasets: [
                        {
                            key: header.text,
                            unit: unit ? unit : '',
                            type: 'line',
                            lineTension: 0.5,
                            borderWidth: 1.3,
                            fill: true,
                            label: unit ? header.text + `(${unit})` : header.text,
                            pointRadius: 2,
                            showLine: true,
                            interpolate: true,
                            borderColor,
                            backgroundColor: headerIndex == 0 ? "rgb(239, 154, 154, 0.5)" : backgroundColors[headerIndex] + '44',
                            data: results.map(result => result[header.sysNodeIdx] === undefined ? null : result[header.sysNodeIdx])
                        }
                    ]
                },
                option: {
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: {
                        duration: 1000,
                        easing: "easeOutQuart",
                    },
                    legend: {
                        display: true,
                        align: "end",
                        labels: {
                            boxWidth: 12,
                            fontColor: "#000000",
                            fontSize: 12
                        }
                    },
                    title: {
                        display: true,
                        align: "start",
                        position: "top",
                        text: header.text,
                        fontSize: 12,
                        fontColor: "#000000",
                    },
                    layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
                    hover: {
                        intersect: false,
                    },
                    elements: {
                        point: {
                            hitRadius: 20,          // 포인트 클릭 반경 조절
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                id: "x-axis-0",
                                stacked: false,
                                offset: true,
                                ticks: {
                                    maxTicksLimit: 10,
                                    autoSkip: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                    maxRotation: 0,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                id: "Left_yAxis",
                                display: true,
                                position: "left",
                                stacked: false,
                                ticks: {
                                    maxTicksLimit: 5,
                                    beginAtZero: true,
                                    fontColor: "#000000",
                                    fontSize: 10,
                                    max: max > 1 ? upperNum : 10,
                                },
                            },
                        ],
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                        position: 'nearest',
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                let value = Math.round(tooltipItem.yLabel * 100) / 100;
                                return `${dataset.key} : ${value} ${dataset.unit ?? ''}`
                            },
                        },
                    }
                }
            };
        })
    }
}