import VueChartJS from 'vue-chartjs';

export default {
  extends: VueChartJS.Bar,
  mixins: [VueChartJS.mixins.reactiveProp],
  props: ['chartData', 'options'],
  data() {
    return {
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  methods: { 
    
  },
};