<template>
    <div>
<!-- <<<<<<< HEAD -->
        <div ref="sankeyChart" style="width: 100%; height: 600px;"></div>
<!-- ======= -->
      <!-- <div class="chart">
      <canvas id="chart"></canvas>
    </div> -->
<!-- >>>>>>> 1c14e592 (temp store) -->
    </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    props: ["data"],
    components: {},
    data() {
        return{
            flowData: null,
        }
    },
    created() {},
    mounted() {
        this.renderChart();
    },
    methods: {
        renderChart() {
            let chart = echarts.init(this.$refs.sankeyChart);

            let option = {
                title: {
                    text: ''
                },
                tooltip: {
                    trigger: 'item',
                    triggerOn: 'mousemove',
                      formatter: function(params) {
            if (params.dataType === 'edge') { 
                console.log('리이잉크',params)
                return `${params.data.source} -> ${params.data.target}: ${params.data.value} ${params.data.unit}`;
            } else if (params.dataType === 'node') { 
                return `${params.name}: ${params.value}`;
            }}
                },
               
                series: [
                    {
                        type: 'sankey',
                        
                        data: this.data.data,
                        links: this.data.links,
                        emphasis: {
                            focus: 'adjacency',
                        },
                        draggable: false,
                        left: 'center', 
                        top: 'middle',   
                        layout: 'none', 
                        width: '80%',      
                        height: '80%',
                        label: {
                        show: true, 
                        formatter: function(params) {
                            console.log('야 이게뭐냐',params);
                        return `${params.name}  ${params.value}`;
                        }  
                        },
                        lineStyle: {
                            color: 'gradient',
                            curveness: 0.1
                        }
                    }
                ]
            };

            chart.setOption(option);
            window.addEventListener("resize", () => {
                chart.resize();
            });
        }
    }
}

</script>

<style></style>