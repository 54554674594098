<template>
    <v-chart style="height: 100%;" :options="option" />
</template>

<script>

export default {
    props: ["hourData"],
    data() {
        return {
            option: {
                tooltip: {
                    position: 'top'
                },
                grid: {
                    height: '70%',
                    width: '90%',
                    top: '20%'
                },
                xAxis: {
                    type: 'category',
                    data: this.hourData.hours, // hours
                    splitArea: {
                        show: true
                    }
                },
                yAxis: {
                    type: 'category',
                    data: this.hourData.days, // days
                    splitArea: {
                        show: true
                    }
                },
                visualMap: {
                    show: false,
                    min: 0,
                    max: 10,
                    calculable: true,
                    orient: 'horizontal',
                    left: 'center',
                    bottom: '25%'
                },
                series: [
                    {
                        // name: 'Punch Card',
                        type: 'heatmap',
                        data: this.hourData.data.map(function (item) {
                            return [item[1], item[0], item[2].toFixed(0) || '-'];
                        }),
                        label: {
                            show: true
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        }
    }
}

</script>