<template>
    <div>
        <div v-if="status" class="col-md-12 card-wrapper">
            <div v-for="(item) in status.relatedPointStatus" :key="item.ptIdx" class="card-container-rltd" @click="handleClick(item.ptIdx)" >
                <span class="card-content-title-rltd">{{ item.dispText }}</span>
                <div>
                    <span class="card-content-value">{{ formatValue(item.ptVal) }}</span>
                    <span class="card-content-unit">
                        {{ $store.state.units.find(unit => unit.value == item.unit).text ?? '-' }}
                    </span>
                </div>
            </div>
            <div v-for="(item, index) in status.statusProp" :key="index" class="d-flex">
                <div v-for="(value, key) in item.statusSum" :key="key" class="card-container-status">
                    <span class="card-content-title-status">{{ key }}</span>
                    <div>
                        <span class="card-content-value">{{ value }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="col-md-12 card-wrapper">
            <span>표시할 데이터가 없습니다.</span>
        </div>
    </div>
</template>

<script>

export default {
    props: ["status"],
    computed: {},
    data() {
        return {}
    },
    created() { },
    mounted() { },
    methods: {
        handleClick(ptIdx) {
            console.log('Clicked point index : ', ptIdx);
            this.$emit('selecteRltdPoint', ptIdx);
        },
        formatValue(value) {
            if (Number.isInteger(value)) {
                return value;
            } else {
                return value.toFixed(2);
            }
        },
    },
}

</script>

<style scoped>
.card-wrapper {
    border-radius: 10px;
    padding: 1rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.card-container-rltd {
    margin: 0px 0.5rem 0px 0.5rem;
    color: white;
    border-radius: 10px;
    min-width: 100px;
    height: 60px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #5775ad;
    box-shadow: 2px 2px 2px gray;
}
.card-content-value {
    font-size: 1.1rem;
    font-weight: 800;
}
.card-container-status {
    margin: 0px 0.5rem 0px 0.5rem;
    border-radius: 10px;
    min-width: 100px;
    height: 60px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #eee;
    box-shadow: 2px 2px 2px gray;
}
.card-content-title-status {
    color: #555;
    font-weight: 600;
}
</style>