<template>
  <div style="height: 100%;">
     <div class="externalStateMenu" v-if="rltdTrendData">
                        <select v-model="externalAirStateCode" class="form-control mr-2" style="width: 8rem;">
                        <option :value="rltdTrendData.headers[0].ptIdx">외기온도</option>
                        <option :value="rltdTrendData.headers[1].ptIdx">외기습도</option>
                    </select>
            </div>
      <div ref="scatterChart" style="width: 100%; height: 80%;"></div>
  </div>

</template>

<script>
import * as echarts from 'echarts';
import ecStat from 'echarts-stat';

export default {

data(){
    return {
        externalAirStateCode:null,
        chartData:[],
        
    }
},
props:["rltdTrendData","baseChartData"],
created(){
    console.log('실행은되나');
//  if (this.rltdTrendData && this.baseChartData ) {
//         console.log('하아아아', this.rltdTrendData, this.baseChartData,);
//         console.log('얘는 외기외기외가리', this.rltdTrendData);
//         console.log('얘는 진짜 전력사용량', this.baseChartData[0].data.datasets[0].data);
//         console.log('얘는 전력사용량라벨', this.baseChartData[0].data.labels);
//         this.rltdTrendData.results.forEach(item => console.log('item이야', item));
//     } else {
//         console.warn("관련 데이터가 정의되지 않았습니다.");
//     }
},
mounted(){
      if (this.rltdTrendData && this.rltdTrendData.headers[0]) {
        // console.log('데이터체크해보자',this.rltdTrendData.headers[0])
        this.externalAirStateCode = this.rltdTrendData.headers[0].ptIdx;
        this.prepareChartData(); // 데이터 준비 후 렌더링
    }
        
},
/**
 * 
 * data() {
    return {
        externalAirStateCode: this.rltdTrendData ? this.rltdTrendData.headers[0].ptIdx : null,
        chartData: [],
    };
},

 */
computed:{
   filteredResults() {
      if (this.rltdTrendData && this.externalAirStateCode) {
        // console.log('우아아아아', this.rltdTrendData.results.map(item=>item[this.externalAirStateCode]))
        return this.rltdTrendData.results.map(item => item[this.externalAirStateCode]);
      }
      return [];
    }
  },
watch:{
externalAirStateCode(){
    this.chartData=[];
    // console.log('익스피디아',this.externalAirStateCode,typeof this.externalAirStateCode);
    // console.log('그래도 할껀해야지',this.rltdTrendData.results.map(item => item[this.externalAirStateCode]));
    this.baseChartData[0].data.datasets[0].data.forEach((item,index)=>{
        // console.log('너는 이게 된다고 생각하냐',[item,this.rltdTrendData.results[index][this.externalAirStateCode]]);
        this.chartData[index]=[item,this.rltdTrendData.results[index][this.externalAirStateCode]];
        });
        // console.log('최수종데이터',this.chartData);
        this.renderChart();

        }
    },
    methods: {
        prepareChartData() {
        this.chartData = [];
        if (this.baseChartData && this.rltdTrendData) {
            this.baseChartData[0].data.datasets[0].data.forEach((item, index) => {
                this.chartData[index] = [item, this.rltdTrendData.results[index][this.externalAirStateCode]];
            });
            console.log('chartData입니다',this.chartData);
            this.renderChart(); 
        }
    }
    ,
    renderChart(){
        const chart = echarts.init(this.$refs.scatterChart);

        const regression = ecStat.regression('linear', this.chartData); 

            regression.points.sort((a, b) => a[0] - b[0]);

         const yValues = this.chartData.map(point => point[1]);
        const minY = Math.min(...yValues) * 0.5;
        const maxY = Math.max(...yValues) * 1.5; 



        const option = {
            title: {
                text: '산포도 차트',
                left: 'center'
            },
        
            textStyle: {
                    fontSize: 20, 
                    fontWeight: 'bold',  
                    color: '#333',  
                    fontFamily: 'Arial' },
            legend: {
                bottom:5,
                textStyle:{
                fontSize:12
             }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                }
            },
            xAxis: {
                name:'전력사용량',
                nameTextStyle:{
                    fontSize:13,
                },
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                }
            },
            
            yAxis: {
                name:this.externalAirStateCode===this.rltdTrendData.headers[0].ptIdx?'외기온도':'외기습도',
               min: minY, 
                max: maxY, 
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                },
                nameTextStyle:{
                    fontSize:13,
                   
                }
            },
            series: [
                {
                    name: this.externalAirStateCode===this.rltdTrendData.headers[0].ptIdx?'외기온도':'외기습도',
                    type: 'scatter',
                    data: this.chartData
                },
                {
                    name: '추새선',
                    type: 'line',
                    data: regression.points,
                    showSymbol: false,
                    smooth: true,
                    markPoint: {
                        label: {
                            formatter: regression.expression,
                            position: 'middle',
                            color: '#333',
                            fontSize: 12
                        },
                        data: [
                            {
                                coord: regression.points[regression.points.length - 1]
                            }
                        ]
                    }
                }
            ]
        };

            chart.setOption(option);
            window.addEventListener("resize", () => {
                chart.resize();
            });
        }
    }
}
</script>

<style scoped>
.externalStateMenu {
    display: flex;
    justify-content: end;
}
</style>
