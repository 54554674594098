<!-- 설비 유형 상세 (EquipTypeDetail) -->
<template>
    <div v-if="status" class="detail-container">
        <div class="detail-header">
            <div v-if="currentNode" class="d-flex justify-content-between col-md-12 detail-title">
                <div class="col-sm-6 d-flex align-items-center">
                    <span class="badge title1"> {{ currentNode.text }} </span>
                    <span class="badge title2"> {{ currentNode.sysNodeIdx }} </span>
                    <span class="badge others"> {{ systemName }} </span>
                    <span v-show="currentNode.nodeType !== 'System'" class="badge others"> {{ equipName }} </span>
                    <span class="badge others"> {{ nodeTypeValue }} </span>
                </div>
                <div class="col-sm-6 p-0 d-flex justify-content-end">
                    <button class="refresh-icon mr-2" :class="{ active: expanded }" @click="changeExpandedTree"
                        v-b-tooltip.hover :title="$t(expanded ? '트리 열기' : '트리 닫기')">
                        <i class="fas fa-columns" aria-hidden="true"></i>
                    </button>

                    <select v-model="queryType" class="form-control mr-2" style="width: 8rem;"
                        @change="changeQueryDate(queryType)">
                        <option :value="null">선택</option>
                        <option :value="'NOW'">실시간</option>
                        <option :value="'DAY'">일간</option>
                        <option :value="'MONTH'">월간</option>
                    </select>

                    <input v-model="statusDate" class="form-control mr-2" style="width: 8rem;" @click.stop
                        :type="queryType == 'MONTH' ? 'month' : 'date'"
                        @change="getEquipStatus(currentNode, statusDate)">
                    <button class="refresh-icon mr-2" @click="prevDay" v-b-tooltip
                        :title="queryType == 'NOW' || queryType == 'DAY' ? '이전 일 조회' : '이전 월 조회'">
                        <i class="fas fa-angle-left" aria-hidden="true"></i>
                    </button>
                    <button class="refresh-icon mr-2" @click="nextDay" v-b-tooltip
                        :title="queryType == 'NOW' || queryType == 'DAY' ? '다음 일 조회' : '다음 월 조회'">
                        <i class="fas fa-angle-right" aria-hidden="true"></i>
                    </button>
                    <button class="refresh-icon" :class="{ active: isRefresh }" style="cursor: pointer;"
                        @click="intervalRefresh" v-b-tooltip.hover :title="isRefresh ? '자동 갱신 끄기' : '자동 갱신 켜기'">
                        <i class="fa fa-redo" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
            <h5 v-else>설비 현황</h5>
        </div>
        <!-- 영향 인자 PANEL -->
        <div v-if="!isEmpty(status.relatedPointStatus)" block v-b-toggle.rltdPoints class="accordion-title"
            style="padding: 3px 10px !important;">
            <div class="d-flex">
                <div>
                    <span v-if="rltdPoints"> <i class="fa fa-chevron-down" aria-hidden="true"></i> </span>
                    <span v-if="!rltdPoints"> <i class="fa fa-chevron-up" aria-hidden="true"></i> </span>
                    <span class="ml-2">영향 인자</span>
                </div>
            </div>
            <span class="status-icon" style="cursor: pointer;" @click="showRltdStatusDetail($event)" v-b-tooltip.hover
                :title="$t('영향 인자 상세보기')">
                <i class="fas fa-ellipsis-h" aria-hidden="true"></i>
            </span>
        </div>
        <b-collapse v-if="!isEmpty(status.relatedPointStatus)" v-model="rltdPoints" ref="rltdPoints" id="rltdPoints"
            class="collapse-body detail-itemview">
            <RltdPointsPanel :status="status" @selectRltdPoint="handleSelectRltdPoint" />
        </b-collapse>

        <!-- 제어 타임라인 PANEL -->
        <div v-if="!isEmpty(status.ctrlTimeline)" block v-b-toggle.timeline class="accordion-title"
            style="padding: 3px 10px !important; height: 42px !important;">
            <div class="d-flex">
                <div class="mr-2">
                    <span v-if="timeline"> <i class="fa fa-chevron-down" aria-hidden="true"></i> </span>
                    <span v-if="!timeline"> <i class="fa fa-chevron-up" aria-hidden="true"></i> </span>
                </div>
                <span>제어 타임라인</span>
            </div>
        </div>
        <b-collapse v-if="!isEmpty(status.ctrlTimeline)" v-model="timeline" ref="timeline" id="timeline"
            class="collapse-body detail-itemview">
            <div ref="timelineContainer" v-if="currentNode"
                class="timelineContainer col-md-12 p-0 d-flex justify-content-center">
                <div style="height: 100%; display: flex; justify-content: center; align-items: center;
                    margin: 0px 10px 0px 10px; cursor: pointer;" @click="prevDay">
                    <span style="font-size: 1.5rem;">
                        <i class="fas fa-angle-left" aria-hidden="true"></i>
                    </span>
                </div>
                <TImeLinePanel :currentNode="currentNode" :events="events" :statusDate="statusDate" />
                <div style="height: 100%; display: flex; justify-content: center; align-items: center;
                    margin: 0px 10px 0px 10px; cursor: pointer;" @click="nextDay">
                    <span style="font-size: 1.5rem;"><i class="fas fa-angle-right" aria-hidden="true"></i></span>
                </div>
            </div>
        </b-collapse>

        <!-- 운전현황 헤더 영역 -->
        <div block v-b-toggle.opStatus class="accordion-title" style="padding: 3px 10px !important;">
            <div class="d-flex">
                <div class="mr-2">
                    <span v-if="opStatus"> <i class="fa fa-chevron-down" aria-hidden="true"></i> </span>
                    <span v-if="!opStatus"> <i class="fa fa-chevron-up" aria-hidden="true"></i> </span>
                </div>
                <span>{{ functionModeText }}</span>
            </div>

            <!-- 운전현황 제어버튼 표시 -->
            <div class="d-flex" style="align-items: center;">
                <!-- 상세 옵션  -->
                <!-- 비교인자 선택 -->
                <div v-if="rltdLineOrgData" class="d-flex" style="align-items: center;">
                    <span>비교 인자 선택</span>
                    <select class="form-control ml-1 mr-2" v-model="selectedRltdPtIdx" id="selectedRltdPtIdx"
                        style="width: 8rem;">
                        <option v-for="related in rltdLineOrgData.headers" :key="related.ptIdx" :value="related.ptIdx">
                            {{ related.dispText }}
                        </option>
                    </select>
                </div>

                <!-- 표시 및 동작 옵션 -->
                <div class="d-flex" style="font-size: 1rem; cursor: pointer; ">
                    <span v-show="filterSystemType.sumAvail == 'N' && currentNode.nodeType == 'Group'"
                        class="status-icon" @click="openTableViewSelect($event)" v-b-tooltip.hover
                        :title="$t('표시 속성 선택')">
                        <i class="fas fa-filter" aria-hidden="true"></i>
                    </span>
                    <span v-if="activeIcon === 'equip-chart' || activeIcon === 'prop-chart'" class="status-icon"
                        :class="{ active: isRltdPoint }" @click="showRltdPointTrend($event, false)" v-b-tooltip.hover
                        :title="$t('영향 인자 표시')">
                        <i class="fas fa-link" aria-hidden="true"></i>
                    </span>
                    <span v-if="activeIcon === 'equip-chart' || activeIcon === 'prop-chart'" class="status-icon"
                        :class="{ active: dragMode }" @click="toggleDragMode($event)" v-b-tooltip.hover
                        :title="dragMode ? '드래그 모드 켜기' : '드래그 모드 끄기'">
                        <i class="fas fa-arrows-alt" aria-hidden="true"></i>
                    </span>
                    <b-dropdown v-if="activeIcon === 'equip-chart' || activeIcon === 'prop-chart'" variant="link"
                        toggle-class="text-decoration-none p-0" no-caret>
                        <template #button-content>
                            <span class="status-icon" style="font-size: 1rem !important;" v-b-tooltip.hover
                                :title="$t('차트 표시수')">
                                <i class="fas fa-caret-down" aria-hidden="true"></i>
                            </span>
                        </template>
                        <b-dropdown-item @click="setChartView('1', $event)">1</b-dropdown-item>
                        <b-dropdown-item @click="setChartView('2', $event)">2</b-dropdown-item>
                        <b-dropdown-item @click="setChartView('3', $event)">3</b-dropdown-item>
                    </b-dropdown>
                    <button v-if="activeIcon == 'org-chart' && filterSystemType.sumAvail == 'Y'" class="status-icon"
                        :class="{ active: isIgnoreZero }" @click="changeIgnoreZero" v-b-tooltip.hover
                        :title="$t(isIgnoreZero ? '빈값 표시' : '빈값 제외')">
                        <i class="fas fa-eye-slash" aria-hidden="true"></i>
                    </button>
                    <b-dropdown v-if="activeIcon == 'org-chart' && filterSystemType.sumAvail == 'Y'" variant="link"
                        toggle-class="text-decoration-none p-0" no-caret>
                        <template #button-content>
                            <span class="status-icon" style="font-size: 1rem !important;" v-b-tooltip.hover
                                :title="$t('단위 표시 방법')">
                                <i class="fas fa-caret-down" aria-hidden="true"></i>
                            </span>
                        </template>
                        <b-dropdown-item @click="setOrgData('percent', $event)">백분율</b-dropdown-item>
                        <b-dropdown-item @click="setOrgData('format', $event)">환산값</b-dropdown-item>
                        <b-dropdown-item @click="setOrgData('origin', $event)">원본값</b-dropdown-item>
                    </b-dropdown>

                    <!-- 기능 모드 -->
                    <button v-show="currentNode.nodeType !== 'Equip' && filterSystemType.sumAvail == 'N'"
                        class="status-icon" :class="{ active: activeIcon === 'tileView' }"
                        @click="handleIconClick('tileView', $event)" v-b-tooltip.hover :title="$t('현황 보기')">
                        <i class="fas fa-th" aria-hidden="true"></i>
                    </button>
                    <button class="status-icon" :class="{ active: activeIcon === 'tableView' }"
                        @click="handleIconClick('pie-chart', $event)" v-b-tooltip.hover :title="$t('비중 분석')">
                        <i class="fas fa-list-ul" aria-hidden="true"></i>
                    </button>
                    <button v-show="filterSystemType.sumAvail == 'Y'" class="status-icon"
                        :class="{ active: activeIcon === 'org-chart' }" @click="handleIconClick('org-chart', $event)"
                        v-b-tooltip.hover :title="$t('구성 분석')">
                        <i class="fas fa-th" aria-hidden="true"></i>
                    </button>
                    <button v-show="filterSystemType.sumAvail == 'Y'" class="status-icon"
                        :class="{ active: activeIcon === 'flow-chart' }" @click="handleIconClick('flow-chart', $event)"
                        v-b-tooltip.hover :title="$t('흐름 분석')">
                        <i class="fas fa-random" aria-hidden="true"></i>
                    </button>
                    <button v-show="filterSystemType.sumAvail == 'Y' && queryType == 'MONTH'" class="status-icon"
                        :class="{ active: activeIcon === 'pattern-chart' }"
                        @click="handleIconClick('pattern-chart', $event)" v-b-tooltip.hover :title="$t(`패턴 분석`)">
                        <i class="fas fa-braille" aria-hidden="true"></i>
                    </button>
                    <button v-show="filterSystemType.sumAvail == 'Y'" class="status-icon"
                        :class="{ active: activeIcon === 'scatter-chart' }"
                        @click="handleIconClick('scatter-chart', $event)" v-b-tooltip.hover title="상관 분석">
                        <i class="fas fa-chart-line"></i>
                    </button>
                    <button v-show="filterSystemType.sumAvail == 'Y' && this.currentNode.nodeType !== 'Equip'"
                        class="status-icon" :class="{ active: activeIcon === 'stack-chart' }"
                        @click="handleIconClick('stack-chart', $event)" v-b-tooltip.hover title="성분 분석">
                        <i class="fas fa-layer-group"></i>
                    </button>

                    <!-- <button v-show="filterSystemType.sumAvail == 'Y'" class="status-icon" :class="{ active: activeIcon === 'compare-chart' }"
                        @click="handleIconClick('compare-chart', $event)" v-b-tooltip.hover title="비교 차트보기">
                        <i class="fas fa-greater-than-equal"></i>
                    </button> -->


                    <!-- <button v-show="filterSystemType.sumAvail == 'Y'" class="status-icon"
                        :class="{ active: activeIcon === 'statistics-popup' }"
                        v-b-tooltip.hover title="분석 보기" @click="showStatisticsModal()">
                        <i class="fas fa-neuter"></i>
                    </button> -->
                    <button class="status-icon" :class="{ active: activeIcon === 'equip-chart' }"
                        @click="handleIconClick('equip-chart', $event)" v-b-tooltip.hover
                        :title="$t(`${currentNode.nodeType !== 'Equip' ? '설비별' : '트랜드'}`)">
                        <i class="fas fa-chart-area" aria-hidden="true"></i>
                    </button>
                    <button v-show="currentNode.nodeType == 'Group' && filterSystemType.sumAvail == 'N'"
                        class="status-icon" :class="{ active: activeIcon === 'prop-chart' }"
                        @click="handleIconClick('prop-chart', $event)" v-b-tooltip.hover :title="$t('속성별')">
                        <i class="fas fa-chart-area" aria-hidden="true"></i>
                    </button>
                    <div v-if="currentNode.nodeType == 'Group' && filterSystemType.sumAvail == 'N'" class="d-flex">
                        <button class="control-icon" @click="handleCtrlModal('Group', $event)" v-b-tooltip.hover
                            :disabled="!ctrlMode" :style="{ backgroundColor: !ctrlMode ? '#eab897' : '' }"
                            :title="$t('그룹 제어')">
                            <i class="fas fa-sitemap" aria-hidden="true"></i>
                        </button>
                        <button class="control-icon" @click="handleCtrlModal('Select', $event)" v-b-tooltip.hover
                            :disabled="!ctrlMode" :style="{ backgroundColor: !ctrlMode ? '#eab897' : '' }"
                            :title="$t('멀티 제어')">
                            <i class="fas fa-tasks" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div v-show="currentNode.nodeType == 'Equip' && filterSystemType.sumAvail == 'N'" v-else>
                        <button class="unit-control-icon" @click="unitEquipControl($event)" :disabled="!ctrlMode"
                            :style="{ backgroundColor: !ctrlMode ? '#e38e8e' : '' }">
                            <!-- <i class="fas fa-share" aria-hidden="true"></i> -->
                            {{ "제어 실행" }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- 운전현황 컨텐츠 영역 -->
        <b-collapse visible ref="opStatus" id="externalStateMenu" class="collapse-body detail-itemview opStatus"
            style="padding: 0.5rem; overflow: auto;">
            <div v-if="activeIcon == 'tableView'">
                <div v-if="filterSystemType.sumAvail == 'N'">
                    <!-- 설비노드인 경우 -->
                    <!-- <div style="height: 180px;">
                        <SummaryGaugeChart v-if="!isEmpty(summary)" :data="summary" />
                    </div> -->
                    <div class="col d-flex flex-wrap justify-content-start align-items-start"
                        style="height: 100%; gap:10px">
                        <div v-for="(item, index) in summary" :key="index" class="gauge-container">
                            <SummaryGaugeChart :data="item" />
                        </div>
                    </div>
                    <!-- - root, system 노드일 경우 설비 유형에 따라 테이블을 여러개 랜더링 하는 코드
                    - 마운트 시점에 문제가 있어서 추후 해결해서 반영할 예정 -->
                    <!-- <div v-if="filterSystemType.sumAvail == 'N' && (currentNode.nodeType == 'Root' || currentNode.nodeType == 'System')">
                        <div v-if="columns.length > 0">
                            <div v-for="(col, index) in columns" :key="index">
                                <TableListComponent v-if="col" ref="TableListComponent" :title="''" :columns="col" :rows="rows"
                                :useFilter="false"
                                :iconClass="'fas fa-share'" :mode="'edit'" :iconField="'ctrl'" :customMaxHeight="'65vh'"
                                :customRowHeight="'55%'" :fixedHeader="false" :keyField="'equipName'" />
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <TableListComponent v-if="columns.length > 0" ref="TableListComponent" :title="''" :columns="columns" :rows="rows"
                            :useFilter="false"
                            :iconClass="'fas fa-share'" :mode="'edit'" :iconField="'ctrl'" :customMaxHeight="'65vh'"
                            :customRowHeight="'55%'" :fixedHeader="false" :keyField="'equipName'"
                            :transCodes="filterSystemType.sumAvail == 'Y' || currentNode.nodeType !== 'Group' || currentNode.nodeType !== 'Equip' ? transCodes : []"
                            :propertyField="'property'" @buttonClick="unitCtrlModal" />
                    </div> -->

                    <TableListComponent ref="TableListComponent" :title="''" :columns="columns" :rows="rows"
                        :useFilter="filterSystemType.sumAvail == 'N' && (currentNode.nodeType == 'Root' || currentNode.nodeType == 'System') ? true : false"
                        :keyField="filterSystemType.sumAvail == 'N' && (currentNode.nodeType == 'Root' || currentNode.nodeType == 'System') ? 'equipIdx' : 'equipName'"
                        :transCodes="filterSystemType.sumAvail == 'Y' || currentNode.nodeType !== 'Group' || currentNode.nodeType !== 'Equip' ? transCodes : []"
                        :groupField="filterSystemType.sumAvail == 'N' && (currentNode.nodeType == 'Root' || currentNode.nodeType == 'System') ? 'groupKey' : ''"
                        :iconClass="'fas fa-share'" :mode="'edit'" :iconField="'ctrl'" :customMaxHeight="'65vh'"
                        :customRowHeight="'55%'" :fixedHeader="false" :propertyField="'property'"
                        @buttonClick="unitCtrlModal" />
                </div>
                <div v-else class="row m-0">
                    <!-- 에너지 부하노드인 경우 -->
                    <div class="row m-0 d-flex" style="height: 300px; width: 100%;">
                        <!-- 1단: 현재 부하 노드의 전체 현황 표시 -->
                        <div class="col-3" style="height: 100%;">
                            <!-- {{ engGaugeData }} -->
                            <GaugeChart v-if="!isEmpty(engGaugeData)" :data="engGaugeData" />
                        </div>
                        <div class="col-5" style="height: 100%;">
                            <DoughnutChart v-if="!isEmpty(engDoughnutData)" :data="engDoughnutData" />
                        </div>
                        <div class="col-4 flex-grow-1" style="padding: 0.5rem; text-align: center;">
                            <div class="text-write">성분 구성</div>
                            <TableListComponent v-if="rows" ref="TableListComponent" :columns="columns" :rows="rows"
                                :useFilter="filterSystemType.sumAvail == 'N' && (currentNode.nodeType == 'Root' || currentNode.nodeType == 'System')"
                                :keyField="filterSystemType.sumAvail == 'N' && (currentNode.nodeType == 'Root' || currentNode.nodeType == 'System') ? 'equipIdx' : 'equipName'"
                                :transCodes="filterSystemType.sumAvail == 'Y' || currentNode.nodeType !== 'Group' || currentNode.nodeType !== 'Equip' ? transCodes : []"
                                :groupField="filterSystemType.sumAvail == 'N' && (currentNode.nodeType == 'Root' || currentNode.nodeType == 'System') ? 'groupKey' : ''"
                                :iconClass="'fas fa-share'" :mode="'view'" :iconField="'ctrl'"
                                :customMaxHeight="'250px'" :customRowHeight="'100%'" :fixedHeader="false"
                                :propertyField="'property'" @buttonClick="unitCtrlModal" />
                        </div>
                    </div>
                    <!-- 2단: 하위 구성요소의 대표값 표시 -->
                    <div class="row" style="height: 50vh; width: 100%; overflow-y: auto; overflow-x: hidden;">
                        <div class="col d-flex flex-wrap justify-content-start align-items-start"
                            style="height: 100%; gap:10px">
                            <div v-for="(item, index) in engGaugeDatas" :key="index" class="gauge-container">
                                <SummaryGaugeChart :data="item" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="activeIcon == 'tileView'">
                <div class="row m-0">
                    <div v-for="item in tails" :key="item.equipIdx" class="col-lg-3 p-5">
                        <div class="tile-view-container">
                            <div class="tile-view-header" @click="unitCtrlModal(item)"
                                :style="{ cursor: ctrlMode ? 'pointer' : '' }">
                                <div style="display: flex; justify-content: center; align-items: center;">
                                    <input v-model="item.checked" type="checkbox"
                                        style="margin-right: 0.5rem; cursor: pointer;">
                                    <i :class="item.icon"
                                        style="font-size: 15px; opacity: 0.35; margin-right: 0.5rem;"></i>
                                    <span>{{ `${item.equipName}` }}</span>
                                </div>
                            </div>
                            <div class="row m-0 tile-view-body">
                                <div v-for="(prop, index) in item.props" :key="index" class="col-lg-4 p-2">
                                    <div class="propContainer" style="font-size: 11px;">
                                        <span style="color: #aaa;">{{ prop.propName }}</span>
                                        <span style="font-weight: bold;">{{ formatNumWithComma(prop.ptVal) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="activeIcon == 'org-chart'">
                <OrgChartPanel ref="OrgChartPanel" v-if="equipStatusData" :data="equipStatusData" :queryType="queryType"
                    :statusDate="statusDate" />
            </div>
            <div v-else-if="activeIcon === 'flow-chart'">
                <FlowChartPanel v-if="flowChartData" :data="flowChartData" />
            </div>
            <div v-else-if="activeIcon === 'pattern-chart'" style="height: 50rem;" class="row m-0">
                <div class="col-12" style="height: 40%;">
                    <SumNodeMainTrendPanel v-if="lineNodeData" :baseData="lineNodeData" :dispBaseIdx="currentNode.sysNodeIdx"
                        :compData=rltdLineOrgData :dispCompIdx="selectedRltdPtIdx" />
                </div>
                <div class="col-12" style="height: 50%;">
                    <div class="row m-0" style="height: 100%;">
                        <div class="col-6" style="height: 100%;">
                            <DailyHeatMapChart v-if="dailyHeatMap" :dailyData="dailyHeatMap" />
                        </div>
                        <div class="col-6" style="height: 100%; overflow-y: auto;">
                            <HourHeatMapChart v-if="hourHeatMap" :hourData="hourHeatMap" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="activeIcon === 'scatter-chart'" style="height: 35rem;">
                <scatter-chart-panel v-if="baseChartData[0]" :rltdTrendData='rltdTrendData'
                    :baseChartData="baseChartData" />
            </div>
            <!-- <div v-else-if="activeIcon === 'compare-chart'">
                <compare-chart-panel/>
            </div> -->
            <div v-else-if="activeIcon === 'stack-chart'">
                <stack-area-chart-panel v-if="this.currentNode.nodeType !== 'Equip'" :baseChartData="baseChartData" />
            </div>
            <div v-else>
                <Draggable v-if="responseChart" v-model="responseChart" class="row" @change="updateChartOrder"
                    @start="onDragStart" @end="onDragEnd" tag="div" :disabled="!dragMode">
                    <div v-for="(data, index) in responseChart" :key="data.key"
                        :class="[chartColumn, { 'dragging': isDragging && dragIndex === index }]"
                        style="height: 270px;">
                        <MixChart :chartData="data.data" :options="data.option"
                            :style="{ height: '100%', padding: '15px' }" />
                    </div>
                </Draggable>
            </div>
        </b-collapse>
        <EquipControlModal v-if="equipData" :ctrlType="ctrlType" :equipData="equipData" :currentNode="currentNode"
            @equip-control="equipControl" @clear-data="clearData" ref="EquipControl" />
        <RltdStatusModal v-if="rltdLiveStatus" ref="RltdStatusModal" :statusDetail="rltdLiveStatus"
            @clear-data="clearRltdLiveStatus" />
        <b-modal centered ref="tableViewSelectModal" size="sm" @hidden="closeTableViewSelect">
            <template #modal-header>
                <h5 class="mb-0">표시 속성</h5>
            </template>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button type="button" class="btn btn-primary ctrl-modal-btn"
                        @click="filterColumns(currentNode, statusDate)">적용</button>
                    <button type="button" class="btn btn-secondary ctrl-modal-btn"
                        @click="closeTableViewSelect">닫기</button>
                </div>
            </template>
            <div class="mb-2">
                <span>차트에 표시될 설비 속성을 선택하세요.</span>
            </div>
            <div class="form-group">
                <div class="form-check mb-2">
                    <input v-model="allCheck" type="checkbox" class="form-check-input" id="all"
                        @click="allCheckChartView">
                    <label class="form-check-label" for="all">전체 선택</label>
                </div>
            </div>
            <div v-if="chartView" class="table-view-container">
                <div class="form-group">
                    <div v-for="(list, index) in chartView" :key="index" class="form-check mb-2">
                        <input v-model="chartView[index].checked" type="checkbox" class="form-check-input" :id="index"
                            @change="changeChartViewStatus">
                        <label class="form-check-label" :for="index">{{ list.value }}</label>
                    </div>
                </div>
            </div>
        </b-modal>
        <!-- <b-modal ref="StatisticsModal" center @hidden='hideStatisticsModal'>
            <template #modal-header>
                <h5>통계적 분석</h5>
            </template>
            <div>선생님 이건 아니잖아요;</div>
            <template #modal-footer>
                <button class="btn btn-secondary" @click="hideStatisticsModal">닫기</button>
            </template>
        </b-modal> -->
    </div>
</template>

<script>
import moment from 'moment';
import backEndApi from "@src/api/backEndApi";
import TableListComponent from '@src/views/component/v2.1/ListDetailView/TableList.vue';
import EquipControlModal from './modal/EquipControlModal.vue';
import RltdStatusModal from './modal/RltdStatusModal.vue';
import MixChart from '@src/components/vue-chartjs/NewMixChart';
import Draggable from 'vuedraggable';
import RltdPointsPanel from './panel/RltdPointsPanel.vue'
import TImeLinePanel from './panel/TImeLinePanel.vue';
import OrgChartPanel from "./panel/OrgChartPanel.vue"
import FlowChartPanel from "./panel/FlowChartPanel.vue"
import SummaryGaugeChart from "./charts/SummaryGaugeChart.vue"
import GaugeChart from './charts/GaugeChart.vue'
import DoughnutChart from './charts/DoughnutChart.vue'
// import LineChart from './charts/LineChart.vue'
import HourHeatMapChart from './charts/HourHeatMapChart.vue'
import trendFunction from './TrendFunction.js'
import SumNodeMainTrendPanel from './panel/SumNodeMainTrendPanel.vue'
import ScatterChartPanel from './panel/ScatterChartPanel.vue'
// import CompareChartPanel from './panel/CompareChartPanel.vue'
import StackAreaChartPanel from './panel/StackAreaChartPanel.vue';
import DailyHeatMapChart from './charts/DailyHeatMapChart.vue';
import summaryGaugeChartData from './charts/summaryGaugeChartData';
import gaugeChartData from './charts/gaugeChartData';


export default {
    components: {
        TableListComponent,
        EquipControlModal,
        RltdStatusModal,
        MixChart,
        Draggable,
        RltdPointsPanel,
        TImeLinePanel,
        OrgChartPanel,
        FlowChartPanel,
        ScatterChartPanel,
        SumNodeMainTrendPanel,
        // CompareChartPanel,
        StackAreaChartPanel,
        SummaryGaugeChart,
        GaugeChart,
        DoughnutChart,
        // LineChart,
        HourHeatMapChart,
        DailyHeatMapChart,
    },
    props: ["data", "currentNode", "expanded"],
    async created() {
        this.getSystemType();
        this.getEquipType();
        // await this.getEquipStatus(this.currentNode, this.today);
    },
    async mounted() {
    },
    beforDestroyed() { },
    data() {
        return {
            transCodes: [
                {
                    field: 'nodeType',
                    codes: this.$store.state.commonCodes.nodeType,
                },
                {
                    field: 'equipType',
                    codes: this.$store.state.transEquipType,
                },
                {
                    field: 'unitType',
                    codes: this.$store.state.unitTypes,
                },
                {
                    field: 'sumUnit',
                    codes: this.$store.state.units,
                },
                {
                    field: 'zoneIdx',
                    codes: this.$store.state.zoneInfo,
                },
                {
                    field: 'equipUseType',
                    codes: this.$store.state.commonCodes.phscPointUsage,
                },
            ],
            pointInfos: [],

            rows: [],
            tails: [],
            equipDetail: [],
            columns: [],
            handleColumns: [],
            handleRowData: null,
            ctrlType: null,
            equipData: null,
            checks: null,

            status: null,
            events: [],
            statusDate: moment(new Date()).format("YYYY-MM-DD"),

            // 운전현황 패널 표시 관련
            functionMode: '',
            activeIcon: 'tableView',

            isRefresh: false,
            interval: null,
            today: moment(new Date()).format("YYYY-MM-DD"),

            groupTrend: null,
            baseChartData: null,

            viewColumn: "1",

            systemName: null,
            equipName: null,
            equipTypeDetail: null,

            rltdLiveStatus: null,
            rltdTrendData: null,
            isRltdPoint: false,

            chartView: [],

            dragIndex: null,
            isDragging: false,
            dragMode: false,

            rltdPoints: false,
            timeline: false,
            opStatus: false,

            scrollContainer: null,
            isDown: false,
            startX: null,
            scrollLeft: null,

            allCheck: true,
            ctrlMode: null,

            checkedEquip: [],
            equipStatusData: null,
            queryType: 'NOW',

            isIgnoreZero: true,
            flowChartData: null,

            summary: null,

            engGaugeData: null,
            engDoughnutData: null,
            engGaugeDatas: null,

            selectedRltdPtIdx : null,
            lineNodeData: null,
            rltdLineOrgData: null,

            dailyHeatMap: null,
            hourHeatMap: null,
        }
    },
    computed: {
        chartColumn() {
            return this.viewColumn == '1' ? 'col-md-12' : this.viewColumn == '2' ? 'col-md-6' : 'col-md-4'
        },
        nodeTypeValue() {
            const nodeType = this.currentNode.nodeType;
            return this.$store.state.commonCodes.nodeType.find(item => item.value == nodeType).text ?? "-";
        },
        responseChart: {
            get() {
                return this.baseChartData;
            },
            set() { }
        },
        filterSystemType() {
            return this.$store.state.systemTypeList.find(type => type.sysType == this.currentNode.sysType);
        },
        functionModeText() {
            let subFunction = this.functionMode ? ` (${this.functionMode})` : ``;
            return '운전 현황' + subFunction; 
        }
    },
    watch: {
        currentNode: {
            immediate: true,
            async handler(newVal) {
                console.log("change currentNode : ", newVal);

                this.getSystemType(newVal);
                this.getEquipType(newVal);

                await this.getEquipTypeDetail(newVal);

                await this.getEquipStatus(newVal, this.statusDate);

                if (Array.isArray(this.status.relatedPointStatus)) {
                    this.rltdPoints = this.status.relatedPointStatus.length > 0 ? true : false;
                } else {
                    this.rltdPoints = false;
                }
                console.log('Related Points : ', this.rltdPoints);


                if (Array.isArray(this.status.ctrlTimeline)) {
                    this.timeline = this.status.ctrlTimeline.length > 0 ? true : false;
                } else {
                    this.timeline = false;
                }

                console.log('Set this.timeline : ', this.timeline);


                this.opStatus = true;
                this.groupTrend = null;
                this.rltdTrendData = null;
                this.equipStatusData = null;
            }
        },
    },
    methods: {
        showStatisticsModal(){
            this.$refs.StatisticsModal.show();
        },
        hideStatisticsModal(){
            this.$refs.StatisticsModal.hide();
        },
        async setOrgData(type, event) {
            this.$refs.OrgChartPanel.formatData(type)
            if (event) await event.stopPropagation();
        },
        async changeExpandedTree() {
            await this.$emit('setExpandedTree');
            if (this.filterSystemType.sumAvail == 'Y') await this.getEquipStatus(this.currentNode, this.statusDate);
        },
        changeQueryDate(queryDate) {
            if (queryDate == 'NOW') {
                this.statusDate = this.today;
                this.getEquipStatus(this.currentNode, this.statusDate);
            } else return;
        },
        formatNumWithComma(value) {
            let formatValue;

            if (!isNaN(value)) {
                formatValue = typeof value == 'number' ? value._comma() : Number(value)._comma()
            } else {
                formatValue = value;
            }
            return formatValue;
        },

        async unitEquipControl(event) {
            await event.stopPropagation();
            let value = await this.$refs.TableListComponent.getSelectProperty().filter(item => item.ioType !== 'In');
            let ctrlData = [];

            value.forEach(item => {
                if (item.selectProp) {
                    ctrlData.push({
                        propCode: item.propCode,
                        ptVal: item.selectProp,
                    })
                }
            })

            let ctrlCmd = {
                equipIdx: this.currentNode.equipIdx,
                ctrlMode: "Basic",
                propList: ctrlData.map((item) => {
                    return {
                        propCode: item.propCode,
                        ptVal: item.ptVal ? item.ptVal : null,
                    }
                })
            }
            try {
                let result = await backEndApi.equips.sendEquipCtrlCmd(ctrlCmd);
                if (result.status == 200) {
                    await this.alertNoti("제어에 성공하였습니다.");
                    setTimeout(async () => {
                        await this.getEquipStatus(this.currentNode)
                    }, 1000)
                }
                else this.alertNoti("제어에 실패하였습니다.")
            } catch (e) {
                console.error(e)
            }
        },
        async toggleDragMode(event) {
            await event.stopPropagation();
            this.dragMode = !this.dragMode;
        },
        async changeIgnoreZero(event) {
            await event.stopPropagation();
            this.isIgnoreZero = !this.isIgnoreZero;
            await this.handleIconClick('org-chart');
        },
        changeChartViewStatus() {
            let status = this.chartView.every(item => item.checked);
            this.allCheck = status ? true : false;
        },
        allCheckChartView() {
            this.allCheck = !this.allCheck;
            if (this.allCheck) {
                this.chartView.forEach(item => {
                    item.checked = true;
                })
            } else {
                this.chartView.forEach(item => {
                    item.checked = false;
                })
            }
        },
        async showRltdStatusDetail(event) {
            await event.stopPropagation();
            await this.getRelatedPointStatus(this.currentNode);
            await this.$refs.RltdStatusModal.showStatusDetailModal();
        },
        async getRelatedPointStatus(data) {
            try {
                let result = await backEndApi.sysNode.getRelatedPointStatus(data.sysNodeIdx);
                if (result.status == 200) {
                    //TableList Component에서 Date Formatter 오류가 발생하여 임시 조치
                    result.data.forEach(item => item.updDt = moment(item.updDt).format('YYYY-MM-DD HH:mm:ss'));
                    this.rltdLiveStatus = result.data;
                }
            } catch (e) {
                console.error(e)
            }
        },
        clearRltdLiveStatus() {
            this.rltdLiveStatus = null;
        },
        async getSystemType(data) {
            if (data) {
                try {
                    let find = this.$store.state.systemTypeList.find(item => item.sysType == data.sysType)
                    this.systemName = find.sysTypeName;
                } catch (e) {
                    console.error(e);
                }
            }
        },
        async getEquipType(data) {
            if (data) {
                try {
                    let find = this.$store.state.equipTypeList.find(item => item.equipType == data.equipType);
                    if (find) this.equipName = find.equipTypeName;
                } catch (e) {
                    console.error(e);
                }
            }
        },
        async getEquipTypeDetail(data) {
            if (data.equipType) {
                try {
                    let result = await backEndApi.equipTypes.getEquipType(data.equipType, "Y");
                    if (result.status == 200) {
                        this.equipTypeDetail = result.data;
                        this.ctrlMode = this.equipTypeDetail.ctrlModes.length > 0 ? true : false
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        },
        async setChartView(number, event) {
            this.viewColumn = number;
            await event.stopPropagation();
        },
        async getEquipStatus(node, date) {
            this.engGaugeData = null;
            this.engDoughnutData = null;
            this.engGaugeDatas = null;
            let result;
            // this.isRltdPoint = false;
            let statusParams = {
                sysNodeIdx: node.sysNodeIdx,
                queryDate: date ? date : this.today,
                queryType: this.queryType ? this.queryType : 'NOW',
                maxDepth: 1,
                ignoreZero: 'Y',
            }
            // const now = new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Seoul' }));
            try {
                if (node.nodeType == 'Group') {
                    result = await backEndApi.sysNode.getGroupOprtStatus(statusParams);
                } else if (node.nodeType == 'System' || node.nodeType == 'Root') {
                    this.equipTypeDetail = [];
                    result = await backEndApi.sysNode.getSystemOprtStatus(statusParams);
                    if (this.filterSystemType.sumAvail == 'N') {
                        const equipTypeArr = Object.keys(result.data.equipStatus).map(key => result.data.equipStatus[key].equipType)
                        const promises = equipTypeArr.map(async (item) => {
                            try {
                                const equipType = await backEndApi.equipTypes.getEquipType(item, "Y");
                                if (equipType.status == 200) return equipType.data;
                            } catch (e) {
                                console.error(e)
                            }
                        })
                        const details = await Promise.all(promises);
                        this.equipTypeDetail = details;
                    }
                } else {
                    result = await backEndApi.sysNode.getEquipOprtStatus(statusParams);
                }

                // API 결과 조회된 equipStatus는 일단 저장하고 활용한다. by badblock
                if (result.data && result.data.equipStatus) {
                    this.equipStatusData = result.data.equipStatus; 
                }

                if (result.data) {
                    this.status = result.data;
                    this.relatedPtIdx = this.status.relatedPointStatus[0].ptIdx;
                    if (result.data.ctrlTimeline && Array.isArray(result.data.ctrlTimeline)) {
                        this.events = result.data.ctrlTimeline.map(item => {

                            // isPast : item.history 객체 유무로 판단 가능
                            // timeline event icon :
                            // fa-user-plus : Man
                            // fa-history: Schd (with no-repeat)
                            // loop : Schd (with repeat)

                            // set event Icon Type : 
                            let eventIcon = 'fa-user-plus';   // Default: Manual
                            if (item.ctrlType == 'Schd') eventIcon = 'fa-history';
                            if (item.plan && item.plan.repeatType != 'None')
                                eventIcon = 'fa-redo';


                            return {
                                ...item,
                                eventIcon,
                                // isPast: itemTime < now
                            }
                        });
                    }

                    // equipStatus가 Array가 아니면 Array로 변환하면 처리에 용의하나
                    // 후위 코드에 오류가 있어 적용하지는 않는다.
                    // if (!Array.isArray(result.data.equipStatus)) {
                    //     const equipStatus = { ...result.data.equipStatus };
                    //     result.data.equipStatus = [];
                    //     result.data.equipStatus.push(equipStatus);
                    // }

                    // Save pointInfos Array
                    this.pointInfos = [];

                    if (result.data.equipStatus && Array.isArray(result.data.equipStatus)) {
                        if (this.currentNode.nodeType == 'System') {

                            console.log("this.pointInfos !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", this.pointInfos);
                        } else {
                            result.data.equipStatus.forEach(equip => {
                                this.pointInfos = _makePointWithEquipStatus(equip);
                            });
                        }
                    }
                    // else {
                    //     const equip = result.data.equipStatus;
                    //     this.pointInfos = _makePointWithEquipStatus(equip);
                    //     let summaryData = _makeEquipSummary(equip);
                    //     this.summary = summaryGaugeChartData.setSummaryGuageData(summaryData, this.$store.state.units)
                    // }
                    if (this.filterSystemType.sumAvail == 'N') {
                        if (!this.isEmpty(result.data.summary)) {
                            this.summary = summaryGaugeChartData.setSummaryGuageData(result.data.summary, this.$store.state.units)
                        } else {
                            const equip = result.data.equipStatus;
                            let summaryData = _makeEquipSummary(equip);
                            this.summary = summaryGaugeChartData.setSummaryGuageData(summaryData, this.$store.state.units)
                        }
                    } else {
                        const equipStatus = result.data.equipStatus;
                        this.engGaugeData = {
                            text: equipStatus[0].text,
                            sumVal: equipStatus[0].sumVal,
                            sumUnit: equipStatus[0].sumUnit,
                            sumRatio: equipStatus[0].sumRatio,
                        }
                        this.engDoughnutData = equipStatus[0].children.map(item => {
                            return {
                                value: item.sumVal ? item.sumVal : item.ptVal,
                                name: item.text,
                                unit: item.sumUnit ? item.sumUnit : item.unit,
                            }
                        })
                        const baseGauges = equipStatus[0].children
                            .sort((a, b) => (b.sumVal ?? b.ptVal) - (a.sumVal ?? a.ptVal))
                            .map(item => {
                                return {
                                    text: item.text,
                                    sumVal: item.sumVal ? item.sumVal : item.ptVal,
                                    sumUnit: item.sumUnit ? item.sumUnit : item.unit,
                                }
                            })
                        this.engGaugeDatas = gaugeChartData.setGaugeData(baseGauges)
                    }

                    await this.setupTable(result.data.equipStatus);
                    this.setChartDataView(result.data.equipStatus);
                }
            } catch (e) {
                console.error(e)
            }
            this.handleIconClick(this.activeIcon)

            // Inner Function by badblock
            function _makeEquipSummary(equip) {
                let summary = [];
                let ioProps = [];
                let calcProps = [];
                let userProps = [];
                if (Array.isArray(equip)) {
                    ioProps = equip[0].ioProps.filter(prop => prop.representSumYn === 'Y').map(prop => prop)
                    calcProps = equip[0].calcProps.filter(prop => prop.representSumYn === 'Y').map(prop => prop)
                    userProps = equip[0].userProps.filter(prop => prop.representSumYn === 'Y').map(prop => prop)
                }

                summary = [...ioProps, ...calcProps, ...userProps];
                return summary;
            }

            function _makePointWithEquipStatus(equip) {
                let pointInfos = [];

                const { equipIdx, equipName } = equip;
                equip.ioProps && equip.ioProps.forEach(ioProp => {
                    const { ptIdx, ptAddr, propCode, ptVal } = ioProp;
                    pointInfos.push({ equipIdx, equipName, ptIdx, ptAddr, propCode, ptVal });
                });
                equip.calcProps && equip.calcProps.forEach(calcProp => {
                    const { ptIdx, ptAddr, propCode, ptVal } = calcProp;
                    pointInfos.push({ equipIdx, equipName, ptIdx, ptAddr, propCode, ptVal });
                });
                equip.userProps && equip.userProps.forEach(userProp => {
                    const { ptIdx, ptAddr, propCode, ptVal } = userProp;
                    pointInfos.push({ equipIdx, equipName, ptIdx, ptAddr, propCode, ptVal });
                });

                return pointInfos;
            }
        },
        setChartDataView(data) {
            if (this.currentNode.nodeType == 'System') return;
            this.chartView = [];
            let propCodeSet = new Set();
            let filterIoProp = [];
            let filterCalcProp = [];

            if (this.currentNode.nodeType == 'Group') {
                if (data[0]?.ioProps) {
                    filterIoProp = data[0].ioProps.filter(col => this.equipTypeDetail.ioProps.some(type => type.propCode == col.propCode))
                }
                if (data[0]?.calcProps) {
                    filterCalcProp = data[0].calcProps.filter(col => this.equipTypeDetail.calcProps.some(type => type.propCode == col.propCode))
                }

                filterIoProp && filterIoProp.forEach(ioProp => propCodeSet.add(ioProp.propCode))
                filterCalcProp && filterCalcProp.forEach(calcProp => propCodeSet.add(calcProp.propCode))

            }
            else {
                if (data.ioProps)
                    filterIoProp = data.ioProps.filter(col => this.equipTypeDetail.ioProps.some(type => type.propCode == col.propCode))
                if (data.calcProps)
                    filterCalcProp = data.calcProps.filter(col => this.equipTypeDetail.calcProps.some(type => type.propCode == col.propCode))

                filterIoProp.forEach(ioProp => propCodeSet.add(ioProp.propCode));
                filterCalcProp.forEach(calcProp => propCodeSet.add(calcProp.propCode));
            }
            this.chartView = Array.from(propCodeSet).map(code => ({ value: code, checked: true }));
        },
        async filterColumns(node, date) {
            let result;
            let statusParams = {
                sysNodeIdx: node.sysNodeIdx,
                queryType: this.queryType,
                queryDate: date ? date : this.today,
            }
            try {
                result = await backEndApi.sysNode.getGroupTrendData(statusParams);
                if (result.status == 200) {
                    // let filterChart = this.currentNode.nodeType == 'Group' ?
                    //     trendFunction.formatChartDatas(result.data, this.equipTypeDetail, this.currentNode, this.rows, this.queryType) :
                    //     trendFunction.equipFormatChartDatas(result.data, this.equipTypeDetail, this.currentNode, this.rows, this.queryType);
                    let filterChart;
                    if (this.currentNode.nodeType == 'Root' || this.currentNode.nodeType == 'System') {
                        filterChart = trendFunction.formatSystemDatas(this.groupTrend, this.currentNode, this.rows, this.queryType)
                    } else if (this.currentNode.nodeType == 'Group') {
                        filterChart = trendFunction.formatChartDatas(this.groupTrend, this.currentNode, this.rows, this.queryType)
                    } else if (this.currentNode.nodeType == 'Equip') {
                        filterChart = trendFunction.equipFormatChartDatas(this.groupTrend, this.equipTypeDetail, this.currentNode, this.rows, this.queryType)
                    } else return;
                    const valuesToRemove = this.chartView.filter(item => item.checked).map(item => item.value);
                    filterChart.forEach((chart) => {
                        chart.data.datasets = chart.data.datasets.filter(dataset => valuesToRemove.includes(dataset.propCode));
                    });
                    if (this.isRltdPoint) {
                        let rltdTrendData = await trendFunction.formatRltdChartDatas(this.rltdTrendData, this.queryType);
                        filterChart.unshift(...rltdTrendData);
                    }
                    this.baseChartData = filterChart;
                }
            } catch (e) {
                console.error(e);
            }
            this.closeTableViewSelect();
        },
        handleSelectRltdPoint(ptIdx) {
            this.selectedRltdPtIdx = ptIdx;
            console.log('Selected RltdPoint : ', this.selectedRltdPtIdx)
        },
        async openTableViewSelect(event) {
            await event.stopPropagation();
            this.$refs.tableViewSelectModal.show();
        },
        closeTableViewSelect() {
            this.$refs.tableViewSelectModal.hide();
        },
        async showRltdPointTrend(event, hold) {
            if (event) await event.stopPropagation();
            if (!hold) this.isRltdPoint = !this.isRltdPoint;

            if (this.isRltdPoint) {
                let rltdTrendData = await trendFunction.formatRltdChartDatas(this.rltdTrendData, this.queryType);
                this.baseChartData.unshift(...rltdTrendData);
            } else {
                const keysToRemove = new Set(this.rltdTrendData.headers.map(item => `${item.ptIdx}-${item.dispText}`));
                this.baseChartData = this.baseChartData.filter(data => !keysToRemove.has(data.key));
            }
        },
        async getGroupTrendData(node, date) {
            this.baseChartData = [];
            let statusParams = {
                sysNodeIdx: node.sysNodeIdx,
                queryType: this.queryType,
                queryDate: date ? date : this.today,
            }
            // let statusParams = {
            //     sysNodeIdx: node.sysNodeIdx,
            //     queryType: (this.queryType == 'NOW' || this.queryType == 'DAY') ? 'DAY' : 'MONTH',
            //     queryDate: date ? date : this.today,
            // }
            try {
                let nodeTrendData;

                if (this.currentNode.nodeType == "Root" || this.currentNode.nodeType == "System") {
                    nodeTrendData = await backEndApi.sysNode.getSystemTrendData(statusParams);
                } else if (this.currentNode.nodeType == "Group") {
                    nodeTrendData = await backEndApi.sysNode.getGroupTrendData(statusParams);
                } else if (this.currentNode.nodeType == "Equip") {
                    nodeTrendData = await backEndApi.sysNode.getEquipTrendData(statusParams);
                } else return;

                if (nodeTrendData.status == 200) {
                    this.groupTrend = nodeTrendData.data;
                    if (this.currentNode.nodeType == 'Root' || this.currentNode.nodeType == 'System') {
                        console.log("catch point!!!!!!!!!!!!!!!")
                        this.baseChartData = trendFunction.formatSystemDatas(this.groupTrend, this.currentNode, this.rows, this.queryType)
                    } else if (this.currentNode.nodeType == 'Group') {
                        this.baseChartData = trendFunction.formatChartDatas(this.groupTrend, this.currentNode, this.rows, this.queryType)
                    } else if (this.currentNode.nodeType == 'Equip') {
                        this.baseChartData = trendFunction.equipFormatChartDatas(this.groupTrend, this.equipTypeDetail, this.currentNode, this.rows, this.queryType)
                    } else {
                        this.baseChartData = [];
                        return;
                    }
                }
            } catch (e) {
                console.error(e);
            }
        },
        async getSumNodeTrendData(node, date) {
            this.baseChartData = [];
            let statusParams = {
                sysNodeIdx: node.sysNodeIdx,
                queryType: this.queryType,
                queryDate: date ? date : this.today,
            }
            // let statusParams = {
            //     sysNodeIdx: node.sysNodeIdx,
            //     queryType: (this.queryType == 'NOW' || this.queryType == 'DAY') ? 'DAY' : 'MONTH',
            //     queryDate: date ? date : this.today,
            // }
            try {
                let nodeTrendData = await backEndApi.sysNode.getSumNodeTrendData(statusParams);
                if (nodeTrendData.status == 200) {
                    this.groupTrend = nodeTrendData.data;
                    this.baseChartData = trendFunction.sumNodeFormatChartDatas(this.groupTrend, this.queryType)
                }
            } catch (e) {
                console.error(e);
            }
        },
        async getTrendDataByProp(node, date) {
            this.baseChartData = [];
            let statusParams = {
                sysNodeIdx: node.sysNodeIdx,
                queryType: this.queryType,
                queryDate: date ? date : this.today,
            }
            try {
                let nodeTrendData = await backEndApi.sysNode.getGroupTrendData(statusParams);
                if (nodeTrendData.status == 200) {
                    this.groupTrend = nodeTrendData.data;
                    this.baseChartData = trendFunction.formatChartDatasByProp(this.groupTrend, this.pointInfos, this.queryType);
                }
            } catch (e) {
                console.error(e);
            }
        },
        async getRelatedPointTrendData(node, date) {
            let statusParams = {
                sysNodeIdx: node.sysNodeIdx,
                queryType: this.queryType,
                queryDate: date ? date : this.today,
            }
            // let statusParams = {
            //     sysNodeIdx: node.sysNodeIdx,
            //     queryType: (this.queryType == 'NOW' || this.queryType == 'DAY') ? 'DAY' : 'MONTH',
            //     queryDate: date ? date : this.today,
            // }
            try {
                let rltdTrendData = await backEndApi.sysNode.getRelatedPointTrendData(statusParams);
                if (rltdTrendData.status == 200) {
                    this.rltdTrendData = rltdTrendData.data;
                }
            } catch (e) {
                console.error(e)
            }
        },

        async setupTable(data) {
            this.columns = [];
            this.rows = [];
            this.tails = [];
            this.handleRowData = [];

            if (this.currentNode.nodeType == 'Root' || this.currentNode.nodeType == 'System') {
                if (this.filterSystemType.sumAvail == 'Y') {
                    this.columns = [
                        {
                            label: "노드명",
                            field: "text",
                            type: "text",
                        },
                        {
                            label: "집계값",
                            field: "value",
                            type: "text",
                            tdClass: 'text-center font-weight-bold  text-dark',
                        },
                        {
                            label: "비중",
                            field: "sumRatio",
                            type: "text",
                        },
                    ];
                    data[0].children.forEach(item => {
                        let row = {
                            text: item.text,
                            value: item.sumVal > 1000 ? `${item.sumVal._comma()} ${item.sumUnit}` : `${item.sumVal} ${item.sumUnit}`,
                            sumRatio: `${item.sumRatio} %`,
                            sumUnit: item.sumUnit,
                            sumVal: item.sumVal,
                        }
                        this.rows.push(row);
                    })
                } else {
                    this.columns = [
                        {
                            label: "상위 노드",
                            field: "groupKey",
                            type: "text",
                        },
                        {
                            label: "설비 ID",
                            field: "equipIdx",
                            type: "text",
                        },
                        {
                            label: "설비명",
                            field: "equipName",
                            type: "text",
                        },
                        {
                            label: "설비 유형",
                            field: "equipType",
                            type: "text",
                        },
                        {
                            label: "설비 사용 용도",
                            field: "equipUseType",
                            type: "text",
                        },
                        {
                            label: "구역",
                            field: "zoneIdx",
                            type: "text",
                            // tdClass: 'text-center font-weight-bold  text-dark',
                        },
                    ];
                    Object.keys(data).forEach((key) => {
                        data[key].equipStatus.forEach(status => {
                            let row = {
                                groupKey: data[key].sysNodeName,
                                equipIdx: status.equipIdx,
                                equipName: status.equipName,
                                equipType: status.equipType,
                                equipUseType: status.equipUseType,
                                zoneIdx: status.zoneIdx
                            }
                            this.rows.push(row);
                        })
                    })
                    /**
                     * - root, system 노드일 경우 설비 유형에 따라 테이블을 여러개 랜더링 하는 코드
                     * - 마운트 시점에 문제가 있어서 원복 후 추후 해결해서 반영할 예정
                     * - rows 데이터 구성해야함
                     */
                    // let tempColumns = [];
                    // let filterColumns = [];
                    // Object.keys(data).forEach(key => {
                    //     if (data[key].equipStatus[0].ioProps) {
                    //         let findType = this.equipTypeDetail.find(type => type.equipType == data[key].equipType);
                    //         tempColumns = data[key].equipStatus[0].ioProps.map(col => {
                    //             let prop = findType.ioProps.find(item => item.propCode == col.propCode)
                    //             if (prop) {
                    //                 const unit = this.$store.state.units.find(unit => unit.value == prop.unit)?.text;
                    //                 return { ...col, 'propName': prop.propName, 'unit': unit, 'enabled': prop.enabled };
                    //             } else {
                    //                 console.warn('Prop not found. ', col.propCode);
                    //             }
                    //         }).filter(item => item)
                    //     }
                    //     filterColumns.push(tempColumns);
                    // })
                    // filterColumns.forEach(item => {
                    //     let col = item.map(type => {
                    //         let label = type.unit ? `${type.propName}(${type.unit})` : type.propName;
                    //         return { label: label, field: type.propCode, type: 'text' }
                    //     })
                    //     this.columns.push(col);
                    // })
                }
            } else if (this.currentNode.nodeType == 'Group') {
                // 단순 필터링에서 propName을 찾아 맵핑해야 함
                if (this.filterSystemType.sumAvail !== "Y") {
                    let filterColumns = [];
                    if (data[0]?.ioProps) {
                        filterColumns = data[0].ioProps.map(col => {
                            let prop = this.equipTypeDetail.ioProps.find(item => item.propCode == col.propCode)

                            if (prop) {
                                // console.log('prop :', prop);
                                const unit = this.$store.state.units.find(unit => unit.value == prop.unit)?.text;
                                return { ...col, 'propName': prop.propName, 'unit': unit, 'enabled': prop.enabled };
                            } else {
                                console.warn('Prop not found. ', col.propCode);
                            }
                        })
                    }

                    // Arrange undefined or null object
                    filterColumns = filterColumns.filter(item => item);

                    this.handleRowData = data;

                    this.columns = [
                        {
                            label: "설비명",
                            field: "rowTitle",
                            type: "text",
                            compositionFields: ['equipName', 'equipIdx'],
                        },
                    ];
                    filterColumns.forEach(prop => {
                        let label = prop.unit ? `${prop.propName} (${prop.unit})` : prop.propName;
                        this.columns.push({ label: label, field: prop.propCode, type: "text", })
                    })

                    this.columns.push({
                        label: "제어",
                        field: "ctrl",
                        tdClass: "text-center",
                        width: "70px",
                    });

                    this.handleColumns = [...this.columns];

                    data.forEach((item) => {
                        let row = {
                            equipIdx: item.equipIdx,
                            equipName: item.equipName,
                            ctrlMode: this.equipTypeDetail.ctrlModes.length > 0 ? true : false,
                        };
                        let tileView = {
                            equipIdx: item.equipIdx,
                            equipName: item.equipName,
                            icon: this.equipTypeDetail.icon,
                            dispColor: this.equipTypeDetail.dispColor,
                            checked: false,
                            ctrlMode: this.equipTypeDetail.ctrlModes.length > 0 ? true : false,
                            props: [],
                        };

                        item.ioProps.forEach(prop => {
                            let find = this.equipTypeDetail.ioProps.find(item => item.propCode == prop.propCode);
                            if (find) {
                                if (find.encodeType == 'Enum') {
                                    if (find.encodeDesc) {
                                        row[prop.propCode] = JSON.parse(find.encodeDesc)[prop.ptVal];

                                        if (find.dispLevel < 3) {
                                            tileView.props.push({
                                                dispLevel: find.dispLevel,
                                                dispOrder: find.dispOrder,
                                                unit: find.unit ? find.unit : "",
                                                propName: find.propName,
                                                ptVal: JSON.parse(find.encodeDesc)[prop.ptVal],
                                            })
                                        }
                                    }
                                    else {
                                        row[prop.propCode] = prop.ptVal;

                                        if (find.dispLevel < 3) {
                                            tileView.props.push({
                                                dispLevel: find.dispLevel,
                                                dispOrder: find.dispOrder,
                                                unit: find.unit ? find.unit : "",
                                                propName: find.propName,
                                                ptVal: JSON.parse(find.encodeDesc)[prop.ptVal],
                                            })
                                        }
                                    }
                                } else if (find.encodeType == 'Number') {
                                    row[prop.propCode] = prop.ptVal;

                                    if (find.dispLevel < 3) {
                                        tileView.props.push({
                                            dispLevel: find.dispLevel,
                                            dispOrder: find.dispOrder,
                                            unit: find.unit ? find.unit : "",
                                            propName: find.propName,
                                            ptVal: prop.ptVal,
                                        })
                                    }

                                } else if (find.encodeType == "None") {
                                    row[prop.propCode] = prop.ptVal;

                                    if (find.dispLevel < 3) {
                                        tileView.props.push({
                                            dispLevel: find.dispLevel,
                                            dispOrder: find.dispOrder,
                                            unit: find.unit ? find.unit : "",
                                            propName: find.propName,
                                            ptVal: prop.ptVal,
                                        })
                                    }
                                } else {
                                    row[prop.propCode] = prop.ptVal;

                                    if (find.dispLevel < 3) {
                                        tileView.props.push({
                                            dispLevel: find.dispLevel,
                                            dispOrder: find.dispOrder,
                                            unit: find.unit ? find.unit : "",
                                            propName: find.propName,
                                            ptVal: prop.ptVal,
                                        })
                                    }
                                }
                            }
                            else row[prop.propCode] = prop.ptVal;
                        })
                        this.rows.push(row)
                        this.tails.push(tileView)
                    })
                } else {
                    this.columns = [
                        // {
                        //     label: "노드 ID",
                        //     field: "sysNodeIdx",
                        //     type: "text",
                        // },
                        {
                            label: "노드명",
                            field: "text",
                            type: "text",
                        },
                        // {
                        //     label: "노드 유형",
                        //     field: "nodeType",
                        //     type: "text",
                        // },
                        // {
                        //     label: "설비 유형",
                        //     field: "equipType",
                        //     type: "text",
                        // },
                        // {
                        //     label: "단위",
                        //     field: "sumUnit",
                        //     type: "text",
                        // },
                        {
                            label: "집계값",
                            field: "value",
                            type: "text",
                            tdClass: 'text-center font-weight-bold  text-dark',
                        },
                        {
                            label: "비중",
                            field: "sumRatio",
                            type: "text",
                        },
                    ]
                    if (!this.isEmpty(data[0])) {
                        data[0].children.forEach(item => {
                            let row = {
                                text: item.text,
                                value: item.sumVal > 1000 ? `${item.sumVal._comma()} ${item.sumUnit}` : `${item.sumVal} ${item.sumUnit}`,
                                sumRatio: `${item.sumRatio} %`,
                                sumUnit: item.sumUnit,
                                sumVal: item.sumVal,
                            }
                            this.rows.push(row);
                        })
                    }
                }

            }
            else if (this.currentNode.nodeType == 'Equip') {
                if (this.filterSystemType.sumAvail !== "Y") {
                    this.columns = [
                        {
                            label: "관제점 ID",
                            field: "ptIdx",
                            width: "80px",
                            type: "text",
                            tdClass: 'text-center font-weight-bold  text-dark',
                        },
                        {
                            label: "설비 항목",
                            field: "propName",
                            width: "100px",
                            type: "text",
                            // tdClass: 'text-center font-weight-bold  text-dark',
                        },
                        {
                            label: "I/O",
                            field: "ioType",
                            width: "60px",
                            type: "text",
                        },
                        {
                            label: "현재값",
                            field: "ptVal",
                            width: "120px",
                            type: "text",
                            tdClass: 'text-center font-weight-bold  text-dark',
                        },
                        {
                            label: "제어 설정",
                            field: "property",
                            type: "text",
                        },
                        {
                            label: "설명",
                            field: "description",
                            width: "30rem",
                            type: "text",
                        },
                    ];

                    this.handleColumns = [...this.columns];

                    data[0].ioProps && data[0].ioProps.forEach(prop => {
                        let find = this.equipTypeDetail.ioProps.find(item => item.propCode == prop.propCode);
                        let row;
                        if (find) {
                            row = {
                                ioType: find.ioType ? find.ioType : "-",
                                propType: "Io",
                                propName: find.propName ? find.propName : "-",
                                ctrlMode: this.equipTypeDetail.ctrlModes.length > 0 ? true : false,
                                encodeType: find.encodeType ? find.encodeType : null,
                                encodeDesc: find.encodeDesc ? find.encodeDesc : null,
                                propCode: find.propCode ? find.propCode : null,
                                description: find.description,
                                selectProp: prop.ptVal,
                                ptIdx: prop.ptIdx ?? null,
                            }

                            const unit = this.$store.state.units.find(unit => unit.value == find.unit)?.text;

                            if (find.encodeType == 'Enum') {
                                if (find.encodeDesc) row.ptVal = JSON.parse(find.encodeDesc)[prop.ptVal]
                                else row.ptVal = prop.ptVal;
                            } else if (find.encodeType == 'Number') {
                                row.ptVal = unit ? `${prop.ptVal ?? '-'} ${unit ?? '-'}` : prop.ptVal;
                            } else {
                                row.ptVal = unit ? `${prop.ptVal ?? '-'} ${unit ?? '-'}` : prop.ptVal;
                            }

                            this.rows.push(row);
                        }
                    })

                    data[0].calcProps && data[0].calcProps.forEach(prop => {
                        let find = this.equipTypeDetail.calcProps.find(item => item.propCode == prop.propCode);
                        let row;
                        if (find) {
                            row = {
                                // ioType: this.$store.state.commonCodes.ioType.find(item => item.value == find.ioType).text,
                                ioType: find.ioType ? find.ioType : "-",
                                propType: "Calc",
                                propName: find.propName ? find.propName : "-",
                                ctrlMode: this.equipTypeDetail.ctrlModes.length > 0 ? true : false,
                                encodeType: find.encodeType ? find.encodeType : null,
                                encodeDesc: find.encodeDesc ? find.encodeDesc : null,
                                propCode: find.propCode ? find.propCode : null,
                                description: find.description ? find.description : "-",
                                ptIdx: prop.ptIdx ?? null,
                            }
                            if (find.encodeDesc) {
                                row.ptVal = JSON.parse(find.encodeDesc)[prop.ptVal]
                            } else {
                                row.ptVal = prop.ptVal
                            }
                            this.rows.push(row);
                        }
                    })

                    data[0].userProps && data[0].userProps.forEach(prop => {
                        let find = this.equipTypeDetail.userProps.find(item => item.propCode == prop.propCode);
                        let row;
                        if (find) {
                            row = {
                                // ioType: this.$store.state.commonCodes.ioType.find(item => item.value == find.ioType).text,
                                ioType: find.ioType ? find.ioType : "-",
                                propType: "User",
                                propName: find.propName ? find.propName : "-",
                                ctrlMode: this.equipTypeDetail.ctrlModes.length > 0 ? true : false,
                                encodeType: find.encodeType ? find.encodeType : null,
                                encodeDesc: find.encodeDesc ? find.encodeDesc : null,
                                propCode: find.propCode ? find.propCode : null,
                                description: find.description ? find.description : "-",
                                ptIdx: prop.ptIdx ?? null,
                                ptVal: prop.ptVal ?? null,
                            }
                            // if (find.encodeDesc) {
                            //     row.ptVal = JSON.parse(find.encodeDesc)[prop.ptVal]
                            // } else {
                            //     row.ptVal = prop.ptVal
                            // }
                            this.rows.push(row);
                        }
                    })
                } else {
                    this.columns = [
                        {
                            label: "관제점",
                            field: "ptIdx",
                            type: "text",
                            width: "60px",                        },
                        {
                            label: "관제점 명",
                            field: "ptName",
                            type: "text",
                            width: "150px",
                        },
                        {
                            label: "집계값",
                            field: "ptVal",
                            type: "text",
                            tdClass: 'text-center font-weight-bold  text-dark',
                        },
                        {
                            label: "비중",
                            field: "ptRatio",
                            type: "text",
                            tdClass: 'text-center font-weight-bold  text-dark',
                        },
                    ];
                    try {
                        // getEquipOprtStatus?sysNodeIdx=235&queryDate=2024-09-12&queryType=DAY&maxDepth=1&ignoreZero=Y
                        // getEquipOprtStatus(searchParams) {
                        //     let filtered = JSON.parse(JSON.stringify(searchParams, (key, value) => (value ?? undefined)));
                        //     return apiClient.get(xemsHost() + `/new/equipSys/getEquipOprtStatus`, { params: filtered });
                        // },

                        // console.log('불필요한 API 호출!!!!!!!!!!!!!!!!!!!!', this.equipStatusData)
                        // let equipPtInfo = await backEndApi.equips.getEquipDetailList(data[0].equipIdx, "Y");

                        // if (equipPtInfo.status == 200) {

                        // 설비노드인 경우 this.equipStatusData.children은 설비노드의 ptMaps의 현재값과 집계값이 저장되어 있음.
                        // 하나의 설비노드가 여러개의 설비와 연결되는 경우는 현재 없기 때문에 equipStatusData는 최대 1개.
                        const ptMaps = this.equipStatusData[0]?.children ?? null;

                        console.log('Check!!!!!!!! ptMaps : ', ptMaps)
                        if (ptMaps){
                            let sum = ptMaps.reduce((acc, pt) => acc + pt.ptVal, 0);

                            ptMaps
                                .sort( (a, b) => b.ptVal - a.ptVal)
                                .forEach(item => {
                                    let ptRatio = item.ptVal / sum * 100;
                                    ptRatio = Number(ptRatio.toFixed(1));
                                    
                                    let row = {
                                        ptIdx: item.ptIdx,
                                        ptName: item.text,
                                        ptVal: (item.ptVal > 1000 ? item.ptVal._comma() : item.ptVal) + item.unit,
                                        ptRatio: ptRatio + '%',
                                        unitType: item.unitType,
                                        unit: item.unit
                                    };

                                    console.log('newItem : ', row);
                                    this.rows.push(row);
                                })
                        }
                    } catch (e) {
                        console.error(e);
                    }
                }
            } else {
                this.columns = [];
                this.rows = [];
            }
        },

        async equipControl(data, property, value, mode, delayMin) {
            if (this.ctrlType === "Unit") {
                let find = this.handleRowData.find(item => item.equipIdx === data.equipIdx);
                let ctrlCmd = {
                    equipIdx: find.equipIdx,
                    ctrlMode: mode,
                    delayInMin: delayMin,
                    propList: property.map((item, index) => {
                        return {
                            propCode: item.propCode,
                            ptVal: value[index] ? Number(value[index]) : 0,
                        }
                    })
                }
                try {
                    let result = await backEndApi.equips.sendEquipCtrlCmd(ctrlCmd);
                    if (result.status == 200) {
                        await this.alertNoti("개별 제어에 성공하였습니다.");
                        // await this.setupTable(this.data);
                        setTimeout(async () => {
                            await this.getEquipStatus(this.currentNode, this.statusDate)
                        }, 1000)
                    }
                    else this.alertNoti("개별 제어에 실패하였습니다.")
                } catch (e) {
                    console.error(e)
                }
            } else if (this.ctrlType === "Select") {
                let equipIdxs = data.map(item => item.equipIdx);
                let ctrlCmd = {
                    equipIdxs,
                    ctrlMode: mode,
                    delayInMin: delayMin,
                    propList: property.map((item, index) => {
                        return {
                            propCode: item.propCode,
                            ptVal: value[index] ? Number(value[index]) : 0,
                        }
                    })
                }
                try {
                    let result = await backEndApi.equips.sendMultiCtrlCmd(ctrlCmd);
                    if (result.status == 200) {
                        await this.alertNoti("멀티 제어에 성공하였습니다.");
                        // await this.setupTable(this.data);
                        setTimeout(async () => {
                            await this.getEquipStatus(this.currentNode, this.statusDate)
                        }, 1000)
                    }
                    else this.alertNoti("멀티 제어에 실패하였습니다.")
                } catch (e) {
                    console.error(e)
                }
            } else if (this.ctrlType === "Group") {
                let ctrlCmd = {
                    sysNodeIdx: this.currentNode.sysNodeIdx,
                    equipType: data[0].equipType,
                    ctrlMode: mode,
                    delayInMin: delayMin,
                    expandSearch: "Y",
                    propList: property.map((item, index) => {
                        return {
                            propCode: item.propCode,
                            ptVal: value[index] ? Number(value[index]) : 0,
                        }
                    })
                }
                try {
                    let result = await backEndApi.sysNode.sendGroupCtrlCmd(ctrlCmd);
                    if (result.status == 200) {
                        await this.alertNoti("그룹 제어에 성공하였습니다.");
                        // await this.setupTable(this.data);
                        setTimeout(async () => {
                            await this.getEquipStatus(this.currentNode, this.statusDate)
                        }, 1000)
                    }
                    else this.alertNoti("그룹 제어에 실패하였습니다.")
                } catch (e) {
                    console.error(e)
                }
            } else return;
        },

        async handleCtrlModal(type, event) {
            await event.stopPropagation();
            await this.openCtrlModal(type);
        },

        async openCtrlModal(type) {
            this.ctrlType = type;
            this.equipData = null;

            if (type === "Select") {
                this.checks = this.$refs.TableListComponent.getCheckedRows();
                // this.checks = this.rows.filter(item => item.checked);
                // console.log(this.checks);
                if (this.checks.length < 1) {
                    this.alertNoti("멀티 제어할 설비를 선택해주세요.");
                    return;
                }
                let find = this.handleRowData.filter(item => this.checks.some(check => check.equipIdx === item.equipIdx));
                console.log(find);
                this.equipData = find;
                this.$nextTick(() => {
                    this.$refs.EquipControl.showControlModal();
                });
            } else if (type === "Group") {
                try {
                    let result = await backEndApi.sysNode.getIncludedEquipStatus(this.currentNode.sysNodeIdx, this.currentNode.equipType);
                    if (result.data) {
                        this.equipData = result.data;
                        this.$nextTick(() => {
                            this.$refs.EquipControl.showControlModal();
                        });
                    }
                } catch (e) {
                    console.error(e);
                }
            }
            else {
                return;
            }
        },
        intervalRefresh() {
            this.isRefresh = !this.isRefresh;

            if (this.isRefresh) {
                this.interval = setInterval(() => this.getEquipStatus(this.currentNode, this.statusDate), 10000);
                console.log("interval refresh start");
            } else {
                clearInterval(this.interval);
                this.interval = null;
                console.log("interval refresh stop");
            }
        },
        async handleIconClick(icon, event) {
            this.equipStatusData = null;
            this.flowChartData = null;
            this.activeIcon = icon;

            if (event) await event.stopPropagation();

            if (this.activeIcon == 'equip-chart') {
                this.functionMode = '트랜드 분석';

                if (this.filterSystemType.sumAvail !== "Y") await this.getGroupTrendData(this.currentNode, this.statusDate);
                else await this.getSumNodeTrendData(this.currentNode, this.statusDate);
                await this.getRelatedPointTrendData(this.currentNode, this.statusDate);
                if (this.isRltdPoint) await this.showRltdPointTrend(null, true);
            } else if (this.activeIcon == 'prop-chart') {
                this.functionMode = '트랜드 분석';

                await this.getTrendDataByProp(this.currentNode, this.statusDate);
                await this.getRelatedPointTrendData(this.currentNode, this.statusDate);

                if (this.isRltdPoint) await this.showRltdPointTrend(null, true);
            } else if (this.activeIcon == 'scatter-chart') {
                this.functionMode = '상관 분석';

                await this.getRelatedPointTrendData(this.currentNode, this.statusDate);
                await this.getSumNodeTrendData(this.currentNode, this.statusDate);
            }
            else if (this.activeIcon == 'stack-chart') {
                this.functionMode = '성분 분석';

                await this.getRelatedPointTrendData(this.currentNode, this.statusDate);
                await this.getSumNodeTrendData(this.currentNode, this.statusDate);
            } else if (this.activeIcon == 'org-chart') {
                this.functionMode = '구성 분석';

                let result;
                let statusParams = {
                    sysNodeIdx: this.currentNode.sysNodeIdx,
                    queryDate: this.statusDate,
                    queryType: this.queryType,
                    ignoreZero: this.isIgnoreZero ? 'Y' : 'N',
                }
                try {
                    if (this.currentNode.nodeType !== 'Equip')
                        result = await backEndApi.sysNode.getSystemOprtStatus(statusParams);
                    else
                        result = await backEndApi.sysNode.getEquipOprtStatus(statusParams);
                    if (result.status == 200) this.equipStatusData = result.data.equipStatus
                } catch (e) {
                    console.error(e)
                }
            } else if (this.activeIcon == 'flow-chart') {
                this.functionMode = '흐름 분석';

                let params = {
                    sysNodeIdx: this.currentNode.sysNodeIdx,
                    queryType: this.queryType,
                    queryDate: this.statusDate,
                    targetField: this.currentNode.sysType !== "ENGSRC" ? 'engUseType' : '',
                }
                try {
                    let result = await backEndApi.sysNode.getEnergyFlowData(params)
                    if (result.status == 200) this.flowChartData = result.data;
                } catch (e) {
                    console.error(e)
                }
            } else if (this.activeIcon == 'pattern-chart') {
                this.functionMode = '패턴 분석';

                this.lineNodeData = null;
                this.rltdLineOrgData = null;

                this.dailyHeatMap = null;
                this.hourHeatMap = null;

                let params = {
                    sysNodeIdx: this.currentNode.sysNodeIdx,
                    queryType: this.queryType,
                    queryDate: this.statusDate,
                }
                let dailyHeatParams = {
                    sysNodeIdx: this.currentNode.sysNodeIdx,
                    queryType: 'DAILY_PATTERN',
                    queryDate: this.statusDate,
                }
                let hourHeatParams = {
                    sysNodeIdx: this.currentNode.sysNodeIdx,
                    queryType: 'HOURLY_PATTERN',
                    queryDate: this.statusDate,
                }

                try {
                    let related = await backEndApi.sysNode.getRelatedPointTrendData(params);
                    let nodeTrend = await backEndApi.sysNode.getSumNodeTrendData(params);
                    let dailyHeat = await backEndApi.sysNode.getSumNodePatternData(dailyHeatParams);
                    let hourHeat = await backEndApi.sysNode.getSumNodePatternData(hourHeatParams);

                    if (related.status == 200) {
                        this.rltdLineOrgData = related.data;

                        // selectedRltdPtIdx가 선택되어 있지 않다면 첫번째 영향인자로 초기화함.
                        // console.log('this.selectedRltdPtIdx : ', this.selectedRltdPtIdx);
                        // console.log('related.data.headers.length : ', related.data.headers.length);

                        if (!this.selectedRltdPtIdx && related.data.headers.length > 0) {
                            this.selectedRltdPtIdx = related.data.headers[0].ptIdx;
                            console.log('Set this.selectedRltdPtIdx : ', this.selectedRltdPtIdx);
                        }
                    }
                    if (nodeTrend.status == 200) {
                        // this.lineNodeData = nodeTrend.data.results.map(item =>
                        //     item[this.currentNode.sysNodeIdx] == null ? 0 : item[this.currentNode.sysNodeIdx])
                        this.lineNodeData = nodeTrend.data;
                        // this.lineNodeDay = nodeTrend.data.results.map(item => moment(item.regDt).format('MM-DD'))
                    }
                    if (dailyHeat.status == 200) {
                        this.dailyHeatMap = dailyHeat.data;
                        console.log("this.dailyHeatMap", this.dailyHeatMap)
                        console.log("this.dailyHeatMap[0][0]!!!!!!!", this.dailyHeatMap[0][0].split('-'))
                    }
                    if (hourHeat.status == 200) {
                        this.hourHeatMap = hourHeat.data;
                        // console.log("this.hourHeatMap", this.hourHeatMap)
                    }
                } catch (e) {
                    console.error(e)
                }
            } else if (this.activeIcon == 'tableView') {
                this.functionMode = '비중 분석';
            }

        },

        async prevDay() {
            const date = new Date(this.statusDate);
            if (this.queryType == 'NOW' || this.queryType == 'DAY') {
                date.setDate(date.getDate() - 1);
                this.statusDate = moment(date).format('YYYY-MM-DD');
            } else if (this.queryType == 'MONTH') {
                date.setMonth(date.getMonth() - 1);
                this.statusDate = moment(date).format('YYYY-MM');
            } else return;
            // date.setDate(date.getDate() - 1);
            // this.statusDate = moment(date).format('YYYY-MM-DD');
            // this.activeIcon = 'tableView';
            // this.isRltdPoint = false;
            await this.getEquipStatus(this.currentNode, this.statusDate);
            if (this.filterSystemType.sumAvail !== "Y") await this.getGroupTrendData(this.currentNode, this.statusDate);
            else await this.getSumNodeTrendData(this.currentNode, this.statusDate);
            await this.getRelatedPointTrendData(this.currentNode, this.statusDate);
        },
        async nextDay() {
            const date = new Date(this.statusDate);
            if (this.queryType == 'NOW' || this.queryType == 'DAY') {
                date.setDate(date.getDate() + 1);
                this.statusDate = moment(date).format('YYYY-MM-DD');
            } else if (this.queryType == 'MONTH') {
                date.setMonth(date.getMonth() + 1);
                this.statusDate = moment(date).format('YYYY-MM');
            } else return;
            // date.setDate(date.getDate() + 1);
            // this.statusDate = moment(date).format('YYYY-MM-DD');
            // this.activeIcon = 'tableView';
            // this.isRltdPoint = false;
            await this.getEquipStatus(this.currentNode, this.statusDate);
            if (this.filterSystemType.sumAvail !== "Y") await this.getGroupTrendData(this.currentNode, this.statusDate);
            else await this.getSumNodeTrendData(this.currentNode, this.statusDate);
            await this.getRelatedPointTrendData(this.currentNode, this.statusDate);
        },
        unitCtrlModal(data) {
            if (!this.ctrlMode) return;
            this.equipData = null;
            let find = this.handleRowData.find(item => item.equipIdx === data.equipIdx);
            this.equipData = find;
            console.log(this.equipData);
            this.ctrlType = "Unit";
            
            this.$nextTick(() => {
                this.$refs.EquipControl.showControlModal();
            });
        },

        clearData() {
            this.equipData = null;
        },
        async updateChartOrder(event) {
            const newOrder = event.moved.newIndex;
            const oldOrder = event.moved.oldIndex;
            const itemToMove = this.baseChartData.splice(oldOrder, 1)[0];
            this.baseChartData.splice(newOrder, 0, itemToMove);
        },
        onDragStart(event) {
            this.dragIndex = event.oldIndex;
            this.isDragging = true;
        },
        onDragEnd() {
            this.isDragging = false;
            this.dragIndex = null;
        },
    },
}

</script>

<style scoped>
.externalStateMenu {
    display: flex;
    justify-content: end;
}

.detail-container {
    padding: 1rem 2rem 1rem 2rem;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: white;
    font-size: 12px;
    overflow-y: auto;
}

.detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.detail-title {
    padding: 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
}

.title-icon {
    font-size: 1rem !important;
}

.table-title {
    font-weight: bold;
    font-size: 13px;
}

.card-content-title-rltn {
    /* font-size: 0.875rem; */
    color: #bbb;
    /* font-weight: 400; */
}

.accordion-title {
    background-color: #ececec;
    padding: 10px;
    margin-bottom: 0px !important;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    border: solid #ececec 1px;
    border-radius: 6px 6px 0px 0px;
}

.detail-itemview {
    /* height: 83%; */
    width: 100%;
    justify-content: center;
    align-items: center;
    /* overflow-y: scroll; */
    border: solid #ececec 1px;
    border-radius: 0px 0px 6px 6px;
    /* padding: 1rem 2rem; */
}

.control-icon {
    color: white;
    background-color: #ED7D31;
    border: solid #fff 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 2rem;
    height: 2rem;
}

.unit-control-icon {
    color: white;
    background-color: #ed3131;
    border: solid #fff 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 4rem;
    height: 2rem;
    font-size: 0.7rem !important;
    font-weight: bold;
}

.unit-control-icon:hover {
    background-color: #e38e8e;
}

.control-icon:hover {
    background-color: #eab897;
}

.status-icon {
    color: #555;
    background-color: #fff;
    border: solid #bbb 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 2rem;
    height: 2rem;
}

.status-icon:hover {
    background-color: #c7c7c7;
}

.status-icon.active {
    color: #fff;
    background-color: #555;
}

.refresh-icon {
    color: #555;
    background-color: #fff;
    border: solid #bbb 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 2rem;
    height: 2rem;
}

.refresh-icon:hover {
    background-color: #c7c7c7;
}

.refresh-icon.active {
    color: #fff;
    background-color: #555;
}

.p-timeline .p-timeline-event-marker {
    background-color: #FF0000 !important;
    /* 이벤트 마커 배경색 변경 */
}

.setting-icon {
    margin-left: 0.5rem;
    font-size: 1.2rem;
    cursor: pointer;
}

.table-view-container {
    padding: 1rem;
    border-radius: 10px;
    border: solid #eee 1px;
}

.dragging {
    border: 1px solid #333333aa;
    border-radius: 10px;
    background-color: #33333311;
    border: 1px solid #333333aa;
    border-radius: 10px;
    background-color: #33333311;
    /* 원하는 border 스타일로 변경하세요 */
}

.timelineContainer {
    user-select: none;
    width: 100%;
    height: 16rem;
}

.opStatus {
    user-select: none;
}

.ctrl-modal-btn {
    flex: 1;
    margin: 0px 5px 0px 5px;
}

.badge {
    font-size: 12px;
    font-weight: 500;
    margin-right: 4px;
    display: inline-block;
    min-width: 20px;
    padding: 0px 10px 0px 10px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    /* background-color: #777; */
    line-height: 22px;
    border-radius: 8px;
}

.badge.title1 {
    background-color: #FF6D00;
    color: #fff;
}

.badge.title2 {
    background-color: #546E7A;
    color: #fff;
}

.badge.others {
    background-color: #CFD8DC;
    color: #000;
}

.tile-view-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #7997ce !important;
    width: 100%;
    padding: 0.3rem;
    color: white;
}

.tile-view-body {
    width: 100%;
    padding: 0.3rem;
    /* background-color: #F5F5F5; */
    color: black;
}

.tile-view-container {
    width: 100%;
    height: 100%;
    box-shadow: 2px 2px 2px #bbb;
    background-color: #ececec;
    border-radius: 5px;
    overflow: hidden;
}

.propContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-container {
    display: flex;
}

.flex-grow {
    flex-grow: 1;
}

.gauge-container {
    width: 250px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

</style>