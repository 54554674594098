<template>
    <div>
        <b-modal centered size="lg" ref="scheduleDetail" @hidden="hideScheduleModal">
            <template #modal-header>
                <span class="detail-modal-header">제어 스케쥴 정보</span>
            </template>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button type="button" class="btn btn-secondary ctrl-modal-btn"
                        @click="hideScheduleModal">닫기</button>
                </div>
            </template>
            <div class="detail-container mb-3">
                <div class="detail-content">
                    <div class="form-row">
                        <div class="form-group col-md-3">
                            <label for="schdCtrlIdx">스케줄 일련번호</label>
                            <input type="text" class="form-control" id="schdCtrlIdx" disabled
                                :value="detail.schdCtrlIdx">
                        </div>
                        <div class="form-group col-md-3">
                            <label for="schdCtrlName">스케줄명</label>
                            <input type="text" class="form-control" id="schdCtrlName" disabled
                                :value="detail.schdCtrlName">
                        </div>
                        <div class="form-group col-md-3">
                            <label for="ctrlTypeCode">적용기간</label>
                            <input type="text" class="form-control" id="ctrlTypeCode" disabled
                                :value="`${detail.startDate} ~ ${detail.endDate}`">
                        </div>
                        <div class="form-group col-md-3">
                            <label for="state">사용여부</label>
                            <input type="text" class="form-control" id="state" disabled
                                :value="$store.state.commonCodes.enabled.find(item => item.value == detail.enabled).text">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-5">
                            <label for="exceptexcept">제어 요일</label>
                            <!-- <input type="text" class="form-control" id="exceptConfig" disabled> -->
                            <div class="d-flex justify-content-around" style="background: #f6f6f6; 
                                padding: 7px 0.75rem 7px 0.75rem; border: 1px solid #d5dbe0; border-radius: 4px;">
                                <div class="form-check ml-1" v-for="(day, i) in $store.state.commonCodes.weekDay"
                                    :key="day.value">
                                    <input v-model="weekDays[i]" type="checkbox" class="form-check-input"
                                        :value="day.value" disabled>
                                    <label class="form-check-label" :for="day.value">{{ day.text }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="groups">제어 그룹수</label>
                            <input type="text" class="form-control" id="groups" disabled :value="detail.groups.length">
                        </div>
                        <div class="form-group col-md-2">
                            <label for="equips">제어 설비수</label>
                            <input type="text" class="form-control" id="equips" disabled :value="detail.equips.length">
                        </div>
                        <div class="form-group col-md-1" @click="openExceptModal"
                            :style="{ cursor: detail.exceptConfig.length > 0 ? 'pointer' : '' }">
                            <label for="except">예외일수</label>
                            <input type="text" class="form-control" id="except" disabled
                                :value="detail.exceptConfig.length">
                        </div>
                        <div class="form-group col-md-2">
                            <label for="ctrlMode">제어구역</label>
                            <input type="text" class="form-control" id="ctrlMode" disabled
                                :value="this.$store.state.zoneInfo.find(item => item.value == detail.zoneIdx).text">
                        </div>
                    </div>
                </div>
            </div>
            <div class="detail-container">
                <div class="detail-content">
                    <TableListComponent v-if="detail.events" ref="TableListComponent" :title="''" :columns="columns" :rows="detail.events"
                        :transCodes="transCodes" :keyField="'schdEventName'" :useFilter="false" :customMaxHeight="'65vh'" :customRowHeight="'55%'" />
                </div>
            </div>
        </b-modal>
        <b-modal ref="excepConfigModal" centered size="sm" @hidden="hideExceptModal">
            <template #modal-header>
                <span class="detail-modal-header">예외일 목록</span>
            </template>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button type="button" class="btn btn-secondary ctrl-modal-btn" @click="hideExceptModal">닫기</button>
                </div>
            </template>
            <div class="detail-container mb-3">
                <div class="detail-content">
                    <div v-for="(except, index) in detail.exceptConfig" :key="index"
                        :class="index === detail.exceptConfig.length - 1 ? '' : 'mb-2'">
                        <span>{{ `${except.exceptName} (${except.exceptDt})` }}</span>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import TableListComponent from '@src/views/component/v2.1/ListDetailView/TableList.vue';
import moment from 'moment'
import { mapState } from 'vuex';

// import backEndApi from '@src/api/backEndApi';

export default {
    props: ["detail"],
    components: {
        TableListComponent,
    },
    data() {
        return {
            weekDays: [],
            columns: [ // 테이블 column
                {
                    label: this.$t("이벤트명"),
                    field: "rowTitle",
                    type: "text",
                    compositionFields: ['schdEventName', 'schdHour'],
                },
                {
                    label: this.$t("반복 주기"),
                    field: "repeatIntervalStr",
                    type: "text",
                },
                {
                    label: this.$t("제어 모드"),
                    field: "ctrlMode",
                    type: "text",
                },
                {
                    label: this.$t("제어 명령"),
                    field: "ctrlCommandStr",
                    type: "text",
                },
            ],
            transCodes: [
                {
                    field: 'repeatType',
                    codes: this.$store.state.commonCodes.repeatType,
                }
            ],
        }
    },
    watch: {},
    computed: {
        ...mapState({
            commonCodes: state => state.commonCodes
        }),
    },
    created() {
        this.detail.startDate = moment(this.detail.startDate).format("YYYY-MM-DD");
        this.detail.endDate = moment(this.detail.endDate).format("YYYY-MM-DD");

        
        this.detail.events.forEach(event => {
            // ctrlCommand > ctrlCommandStr 변환
            event.ctrlCommandStr = this.formatCtrlCommand(event.ctrlCommand);

            // 스케쥴 시간 변환
            if (event.repeatType != "None") {
                event.schdTimeStr = `${event.schdHour} ~ ${event.endHour}`
            } else {
                event.schdTimeStr = `${event.schdHour}`
            }

            // repeatInterval > repeatIntervalStr 변환
            if (event.repeatType != "None") {
                event.repeatIntervalStr = `${event.repeatInterval} ${this.getRepeatType(this.detail.repeatType)}`
            } else {
                event.repeatIntervalStr = `반복 없음`
            }
        })

        this.commonCodes.weekDay.forEach(item => {
            let find = this.detail.repeatConfig[item.value];
            if (find) this.weekDays.push(find === "Y" ? true : false);
            else this.weekDays.push(false);
        })
    },
    mounted() {
        console.log("ScheduleModal Mounted!!!");
    },
    destroyed() {
        console.log("ScheduleModal Destroyed!!!")
    },
    methods: {
        showScheduleModal() {
            this.$refs.scheduleDetail.show();
        },
        hideScheduleModal() {
            this.$refs.scheduleDetail.hide();
            this.$emit("clear-data");
        },
        openExceptModal() {
            if (this.detail.exceptConfig.length == 0) return;
            this.$refs.excepConfigModal.show();
        },
        hideExceptModal() {
            this.$refs.excepConfigModal.hide();
        },
        formatCtrlCommand(cmd) {
            const cmdObj = typeof cmd == 'string' ? JSON.parse(cmd) : cmd;
            
            return Object.entries(cmdObj)
                .map(([key, val]) => `${key}:${typeof val === 'string' ? `"${val}"` : val}`)
                .join(',');        
        },
        getRepeatType(repeatType) {
            const codeItem = this.commonCodes.repeatType.find(item => item.value == repeatType);
            return codeItem ? codeItem.text : '-';
        },
    },
}
</script>

<style scoped>
.detail-container {
    height: 100%;
    /* width: 100%; */
    border-radius: 8px;
    border: solid #eee 1px;
    /* background-color: white; */
    /* margin: 1rem; */
    font-size: 12px;
}

.detail-placeholder {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.detail-header {
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;
    padding: 0.8rem 1rem;
    /* margin: 1rem 0rem; */
}

.detail-content {
    height: 88%;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    border: solid #eee 1px;
    padding: 1rem 1rem;
    /* overflow-y: auto; */
}

.detail-bottom {
    display: flex;
    justify-content: space-between;
}

.form-check {
    display: flex !important;
    align-items: center;
    margin-left: 1rem;
}

.detail-modal-header {
    font-size: 14px;
    font-weight: bold;
}

.ctrl-modal-btn {
    flex: 1;
    margin: 0px 5px 0px 5px;
}

.list-con {
    border-radius: 8px;
    border: solid #eee 1px;
    padding: 1rem;
}
</style>