<template>
  <div :style="{ height: dynamicHeight + 'px', width: '100%', overflow:'auto'}">
    <div ref="barChart" style="width: 100%; height: 100%;"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    data() {
        return {
            propList: [],
        };
    },
    computed: {
        dynamicHeight() {
            return Math.max(this.propList.length * 10, 190); 
        }
    },
    props: ["data"],
    created() {
        this.sortData(this.data);
    },
    mounted() {
        console.log('데이타 받아라!', this.data);
        this.renderChart();
    },
    methods: {
     sortData(dataArr) {
    console.log('가공되지 않은 데이터', dataArr);
    
    if (dataArr) {
        let sortedArr = dataArr.sort((a, b) =>a.sumVal- b.sumVal );
        this.propList=sortedArr.map(item=>item.text);
        console.log('프로퍼',this.propList);
      
    }
}
,
        renderChart() {
            const chart = echarts.init(this.$refs.barChart);

            const option = {
                grid: {
                    left: '15%',
                    right: '15%',
                    bottom: '10%',
                    top: '10%',
                      containLabel: true
                },
                tooltip: {
                    formatter: (params) => {
                        console.log('파람에는 어떤데이터가 들어올까', params);
                        return `${params.data.value} ${params.data.unit} ${params.data.ratio} %`;
                    },
                    textStyle: {
                        fontSize: 18,
                        fontWeight: 'bold',

                    },
                 
                    
                },
                legend: {},
                yAxis: {
                    type: 'category',
                    data: this.propList,
                    axisLabel: {
                        fontSize: 15,
                        show:false
                    }
                },
                xAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: this.propList,
                        data: this.data.map(item => ({
                            value: item.sumVal,
                            unit: item.sumUnit,
                            ratio: item.sumRatio
                        })),
                        type: 'bar',
                        colorBy: 'data',
                        // barCategoryGap: 0,
                        cursor: "auto",
                        barMaxWidth: 50,
                        barMinWidth: 20,
                        // barWidth:'50',
                        label: {
                            show: true,
                            position: 'insideLeft',
                            rich:{
                                 bold: {
                                 fontWeight: 'bold',
                            },
                            unit: {
                            color: 'white',
                          
                             },
                            
                            },
                            formatter: (params) => {
                                console.log('파란하늘', params);
                                  if (params.data.unit) {
                        return `{bold|${params.name}} {unit| ${params.data.value} ${params.data.unit}}`;
                    } else {
                        return `{bold|${params.name}} {unit| ${params.data.value}}`;
                    }
                                // return params.data.unit ? `${params.name} ${params.data.value} ${params.data.unit}` :  `${params.name} ${params.data.value}`;
                            },
                            emphasis: {
                                focus: 'self'
                            }
                        }
                    }
                ]
            };

            chart.setOption(option);
            window.addEventListener("resize", () => {
                chart.resize();
            });
        }
    }
}
</script>

<style>

</style>
