<template>
    <div style="max-height: 400px;">
        <!-- {{ ptMapList }} -->
        <div class="col-12 p-0 d-flex justify-content-between">
            <span style="font-size: 14px; font-weight: 700;">{{ `${main.nodeName} (${main.sysNodeIdx})` }}</span>
            <span style="font-size: 14px; font-weight: 700;">{{ `집계값: ${main.sumVal} ${main.unit}` }}</span>
        </div>
        <TableListComponent ref="TableListComponent" :title="''" :columns="columns" :rows="ptMapList"
            :transCodes="transCodes" :useFilter="false" :customMaxHeight="'350px'" />
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import TableListComponent from '@src/views/component/v2.1/ListDetailView/TableList.vue';

export default {
    props: ["main"],
    components: {
        TableListComponent,
    },
    data() {
        return {
            ptMapList: null,
            totalValue: 0,
            columns: [
                {
                    label: "관제점 ID",
                    field: "ptIdx",
                    type: "text",
                },
                {
                    label: "관제점명",
                    field: "ptName",
                    type: "text",
                },
                {
                    label: "관제점 주소",
                    field: "ptAddr",
                    type: "text",
                },
                {
                    label: "현재값",
                    field: "ptVal",
                    type: "text",
                },
                {
                    label: "단위",
                    field: "unit",
                    type: "text",
                },
                {
                    label: "관제점 관계유형",
                    field: "ptReltnType",
                    type: "text",
                },
            ],
            transCodes: [
                {
                    field: 'ptReltnType',
                    codes: this.$store.state.commonCodes.ptReltnType,
                },
            ],
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        async getPtMapList(idx, type, date) {
            let params = {
                sysNodeIdx: idx,
                queryType: type,
                queryDate: date,
            }
            try {
                let result = await backEndApi.sysNode.newGetIncludedEquipStatus(params);
                if (result.status == 200 && result.data[0].ptMaps) {
                    this.ptMapList = result.data[0].ptMaps;
                    // result.data.ptMaps.forEach
                }
                else this.alertNoti("데이터를 조회할 수 없습니다.")
            } catch (e) {
                console.error(e)
            }
        },
    },
}

</script>