<template>
    <div>
        <b-modal centered ref="statusDetail" size="xl" @hidden="hideStatusDetailModal">
            <template #modal-header>
                <span class="ctrl-modal-header">영향 인자 목록</span>
            </template>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button type="button" class="btn btn-secondary ctrl-modal-btn"
                        @click="hideStatusDetailModal">닫기</button>
                </div>
            </template>
            <div class="detail-container mb-3">
                <div class="detail-content">
                    <TableListComponent ref="TableListComponent" :title="''" :columns="columns" :rows="statusDetail" 
                        :useFilter="false" :transCodes="transCodes" :keyField="'sysNodeName'" :mode="'view'"
                        :customMaxHeight="'65vh'" :customRowHeight="'55%'" />
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import TableListComponent from '@src/views/component/v2.1/ListDetailView/TableList.vue';
// import moment from 'moment'
// import backEndApi from '@src/api/backEndApi';

export default {
    props: ["statusDetail"],
    components: {
        TableListComponent,
    },
    data() {
        return {
            columns: [
                {
                    label: this.$t("표시명"),
                    field: "dispText",
                    type: "text",
                },  
                {
                    label: this.$t("계통 노드"),
                    field: "rowTitle",
                    type: "text",
                    compositionFields: ['sysNodeName', 'sysNodeIdx'],
                },             
                {
                    label: this.$t("관계유형"),
                    field: "ptReltnType",
                    type: "text",
                },
                {
                    label: this.$t("영향범위"),
                    field: "affectRange",
                    type: "text",
                },
                {
                    label: this.$t("관제점 ID"),
                    field: "ptIdx",
                    type: "text",
                },                
                {
                    label: this.$t("관제점 주소"),
                    field: "ptAddr2",
                    type: "text",
                    compositionFields: ['ptAddr', 'ptName'],
                },
                {
                    label: this.$t("집계유형"),
                    field: "ptTypeCode",
                    type: "text",
                },                
                {
                    label: this.$t("현재값"),
                    field: "ptVal",
                    type: "text",
                },
                {
                    label: this.$t("단위"),
                    field: "unitSmallCode",
                    type: "text",
                },
                {
                    label: this.$t("갱신시간"),
                    field: "updDt",
                    type: "text",
                    // formatter: (value) => {
                    //     // ISO8601 날짜를 'YYYY-MM-DD HH:mm:ss' 형식으로 변환
                    //     return moment(value).format('YYYY-MM-DD HH:mm:ss');
                    // }
                },
            ],
            transCodes: [
                {
                    field: 'ptReltnType',
                    codes: this.$store.state.commonCodes.ptReltnType,
                },
                {
                    field: 'ptTypeCode',
                    codes: this.$store.state.commonCodes.ptTypeCode,
                },
                {
                    field: 'affectRange',
                    codes: this.$store.state.commonCodes.affectRange,
                },
                {
                    field: 'unitSmallCode',
                    codes: this.$store.state.units,
                },
            ],
        }
    },
    watch: {},
    computed: {
    },
    created() {
        // this.statusDetail.updDt = moment(this.statusDetail.updDt).format("YYYY-MM-DD HH:mm");
    },
    mounted() {
        console.log("statusDetailModal Mounted!!!");
    },
    destroyed() {
        console.log("statusDetailModal Destroyed!!!")
    },
    methods: {
        showStatusDetailModal() {
            this.$refs.statusDetail.show();
        },
        hideStatusDetailModal() {
            this.$refs.statusDetail.hide();
            this.$emit("clear-data");
        },
    },
}
</script>

<style scoped>
.detail-container {
    height: 100%;
    /* width: 100%; */
    border-radius: 8px;
    border: solid #eee 1px;
    /* background-color: white; */
    /* margin: 1rem; */
    font-size: 12px;
}

.detail-placeholder {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.detail-header {
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;
    padding: 0.8rem 1rem;
    /* margin: 1rem 0rem; */
}

.detail-content {
    height: 88%;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    border: solid #eee 1px;
    padding: 1rem 1rem;
    /* overflow-y: auto; */
}

.detail-bottom {
    display: flex;
    justify-content: space-between;
}

.form-check {
    display: flex !important;
    align-items: center;
    margin-left: 1rem;
}

.ctrl-modal-header {
    font-size: 14px;
    font-weight: bold;
}

.ctrl-modal-btn {
    flex: 1;
    margin: 0px 5px 0px 5px;
}

.list-con {
    border-radius: 8px;
    border: solid #eee 1px;
    padding: 1rem;
}
</style>