<template>
    <div class="row m-0">
        <!-- {{ ptMapList }} -->
        <div class="col-12 p-0 d-flex justify-content-between">
            <span style="font-size: 14px; font-weight: 700;">{{ `${main.nodeName} (${main.sysNodeIdx})` }}</span>
            <span style="font-size: 14px; font-weight: 700;">{{ `집계값: ${main.sumVal} ${main.unit}` }}</span>
        </div>
        <TableListComponent ref="TableListComponent" :title="''" :columns="columns" :rows="data"
            :transCodes="transCodes" :useFilter="false" />
    </div>
</template>

<script>
import TableListComponent from '@src/views/component/v2.1/ListDetailView/TableList.vue';

export default {
    props: ["data", "main"],
    components: {
        TableListComponent,
    },
    data() {
        return {
            ptMapList: null,
            totalValue: 0,
            columns: [
                {
                    label: "노드 ID",
                    field: "sysNodeIdx",
                    type: "text",
                },
                {
                    label: "노드명",
                    field: "text",
                    type: "text",
                },
                {
                    label: "노드 유형",
                    field: "nodeType",
                    type: "text",
                },
                {
                    label: "설비 유형",
                    field: "equipType",
                    type: "text",
                },
                {
                    label: "단위",
                    field: "sumUnit",
                    type: "text",
                },
                {
                    label: "집계값",
                    field: "sumVal",
                    type: "text",
                    tdClass: 'text-center font-weight-bold  text-dark',
                },
                {
                    label: "비중(%)",
                    field: "sumRatio",
                    type: "text",
                },
            ],
            transCodes: [
                {
                    field: 'nodeType',
                    codes: this.$store.state.commonCodes.nodeType,
                },
                {
                    field: 'sumUnit',
                    codes: this.$store.state.units,
                },
                {
                    field: 'equipType',
                    codes: this.$store.state.transEquipType,
                },
            ],
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {},
}

</script>