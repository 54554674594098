<template>
    <div>
        <b-modal centered ref="AddNode" @hidden="hideAddNodeModal">
            <template #modal-header>
                <span class="ctrl-modal-header">설비 노드 추가</span>
            </template>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button type="button" class="btn btn-primary ctrl-modal-btn" @click="AddSysNode">추가</button>
                    <button type="button" class="btn btn-secondary ctrl-modal-btn" @click="hideAddNodeModal">닫기</button>
                </div>
            </template>
            <div class="detail-container mb-3">
                <div class="detail-content">
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="sysNodeName">노드명</label>
                            <input v-model="nodeInfo.sysNodeName" type="text" class="form-control" id="sysNodeName">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="nodeType">노드 유형</label>
                            <select v-model="nodeInfo.nodeType" class="form-control" id="nodeType">
                                <option value="">선택</option>
                                <option value="Root">Root</option>
                                <option value="Group">Group</option>
                            </select>
                        </div>
                    </div>
                    <!-- <div class="detail-content">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="ONOFF">ONOFF</label>
                                <select class="form-control" id="ONOFF">
                                    <option :value="0">OFF</option>
                                    <option :value="1">ON</option>
                                </select>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
// import backEndApi from "@src/api/backEndApi";

export default {
    props: [],
    components: {
    },
    data() {
        return {
            nodeInfo: {
                sysNodeName: "",
                nodeType: "",
            }
        }
    },
    watch: {
    },
    computed: {
    },
    created() {
    },
    mounted() {
    },
    destroyed() { },
    methods: {
        showAddNodeModal() {
            this.$refs.AddNode.show();
        },
        hideAddNodeModal() {
            this.$refs.AddNode.hide();
        },
        async AddSysNode() {
            await this.$emit("add-node", this.nodeInfo);
            this.hideAddNodeModal();
        }
    },
}
</script>

<style scoped>
.detail-container {
    height: 100%;
    /* width: 100%; */
    border-radius: 8px;
    border: solid #eee 1px;
    /* background-color: white; */
    /* margin: 1rem; */
    font-size: 12px;
}

.detail-placeholder {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.detail-header {
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;
    padding: 0.8rem 1rem;
    /* margin: 1rem 0rem; */
}

.detail-content {
    height: 88%;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    border: solid #eee 1px;
    padding: 1rem 1rem;
    /* overflow-y: auto; */
}

.detail-bottom {
    display: flex;
    justify-content: space-between;
}

.form-check {
    display: flex !important;
    align-items: center;
    margin-left: 1rem;
}

.ctrl-modal-header {
    font-size: 14px;
    font-weight: bold;
}

.ctrl-modal-btn {
    flex: 1;
    margin: 0px 5px 0px 5px;
}
</style>