<template>
  <div>
    <div ref="chart" style="width: 100%; height: 400px;"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  props: ["baseChartData"],
  data(){
    return {
        series:null,
        chart:null
    }
  },
  computed:{
    dataValidationFunc(){
      return  this.baseChartData&&this.baseChartData.length>1;
    }
  },
  mounted() {
   this.dataValidationFunc&&this.initChart();
  },
  watch: {
    baseChartData() {
      
      this.series=null;
      this.dataValidationFunc&&this.initChart(); 
    }
  },
  methods: {
    initChart() {
      
      if(this.chart){
        this.chart.dispose();
     }
     
      this.chart = echarts.init(this.$refs.chart);
     
      const labels = this.dataValidationFunc ? this.baseChartData[0].data.labels : [];
this.series = this.dataValidationFunc
  ? this.baseChartData
      .filter((_, index) => index !== 0) 
      .map((chartData) => {
        return {
          name: chartData.data.datasets[0].label,
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: chartData.data.datasets[0].data,
        };
      })
  : [];

      const option = {
        title: {
        
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: this.baseChartData
            .filter((_, index) => index !== 0)
            .map(chartData => chartData.data.datasets[0].label) 
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: labels 
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: this.series 
      };

      this.chart.setOption(option);

      window.addEventListener('resize', () => {
        this.chart.resize();
      });
    },
    
  }
};
</script>

<style>

</style>
